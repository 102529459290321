import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';

import { GrupoFiliacao } from '../../model/grupo-filiacao';
import { environment } from '../../../environments/environment';
import { SelectSindicatoService } from 'app/shared/components/select-sindicato/select-sindicato.service';

@Injectable({
  providedIn: 'root',
})
export class GestaoSindicalService {
  // Private
  private _cadastrosPessoasVinculo: BehaviorSubject<any> = new BehaviorSubject(
    []
  );

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _sindicatos: SelectSindicatoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for cadastrosPessoasVinculo
   */
  get cadastrosPessoasVinculo$(): Observable<GrupoFiliacao[]> {
    return this._cadastrosPessoasVinculo.asObservable();
  }

  /**
   * Getter for sindicatosPorUsuario
   */
  get sindicatosPorUsuario$(): Observable<GrupoFiliacao[]> {
    return this._sindicatos.sindicatos$;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  limparCadastrosPessoasVinculo(): void {
    this._cadastrosPessoasVinculo.next([]);
  }

  filtrarPessoasVinculo(filtro): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/pessoa/filtrarPessoasVinculo`, filtro)
      .pipe(
        catchError((error) => {
          throw new Error(error);
        }),
        tap((response: any) => {
          this._cadastrosPessoasVinculo.next(response);
        })
      );
  }

  exportarCadastroPessoasVinculo(pessoas): Observable<any> {
    return this._httpClient.post(
      `${environment.api}/pessoa/exportarCadastroPessoasVinculo/excel`,
      pessoas,
      { observe: 'response', responseType: 'blob' }
    );
  }

  exportarFichaAssociacao(body: any): Observable<any> {
    return this._httpClient.post(
      `${environment.api}/pessoa/exportarFichaAssociado`,
      body,
      { observe: 'response', responseType: 'blob' }
    );
  }
}
