import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Associado } from '../../../model/associado';
import { environment } from 'environments/environment';
import { Pessoa } from 'app/model/pessoa';
import { LancamentoFluxoCaixa } from 'app/model/lancamento-fluxo-caixa';


@Injectable({
  providedIn: 'root'
})
export class AssociadoService {

  constructor(private http: HttpClient) { }

  buscarPessoaPorCPF(cpf: string): Observable<Pessoa> {
    return this.http.get<Pessoa>(`${environment.api}/pessoa/salvarPessoaDaReceitaFederal/${cpf}`);
  }

  getBySindicato(grupoId: string): Observable<Associado[]> {
    return this.http.get<Associado[]>(`${environment.api}/associado/buscarPorGrupo/${grupoId}`)
      .pipe(catchError(this.handleError));
  }

  getById(id: string): Observable<Associado> {
    return this.http.get<Associado>(`${environment.api}/associado/buscarAssociado/${id}`)
      .pipe(catchError(this.handleError));
  }

  create(associado: Associado): Observable<Associado> {
    return this.http.post<Associado>(`${environment.api}/associado`, associado)
      .pipe(catchError(this.handleError));
  }


  update(id: string, associado: Associado): Observable<Associado> {
    return this.http.put<Associado>(`${environment.api}/associado/${id}`, associado)
      .pipe(catchError(this.handleError));
  }

  getTotalAssociadosBySindicato(sindicatoId: string) {
    return this.http.get<number>(`${environment.api}/associado/total/${sindicatoId}`);
  }

  getTotalAtivosBySindicato(sindicatoId: string) {
    return this.http.get<number>(`${environment.api}/associado/totalAtivos/${sindicatoId}`);
  }

  getTotalInativosBySindicato(sindicatoId: string) {
    return this.http.get<number>(`${environment.api}/associado/totalInativos/${sindicatoId}`);
  }

  getTotalAdimplentesBySindicato(sindicatoId: string) {
    return this.http.get<number>(`${environment.api}/associado/totalAdimplentes/${sindicatoId}`);
  }

  getTotalInadimplentesBySindicato(sindicatoId: string) {
    return this.http.get<number>(`${environment.api}/associado/totalInadimplentes/${sindicatoId}`);
  }

  criarPagamento(pagamento: LancamentoFluxoCaixa): Observable<LancamentoFluxoCaixa> {
    return this.http.post<LancamentoFluxoCaixa>(`${environment.api}/financeiro/saveFluxoAssociado`, pagamento)
      .pipe(catchError(this.handleErrorPagamento));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Ocorreu um erro:', error);
    return throwError('Algo deu errado. Por favor, tente novamente mais tarde.');
  }

  private handleErrorPagamento(error: HttpErrorResponse) {

    if (error.status === 400 && error.error) {
      return throwError(() => new Error(error.error));
    }
    return throwError(() => new Error(`Ocorreu um erro inesperado.`));
  }

}
