import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseUtilsService } from '@fuse/services/utils';
import { optionsLabelValue } from 'app/core/utils/utils';
import { Campanha } from 'app/model/campanha';
import { Conversa } from 'app/model/conversa';
import { MensagemAprovada } from 'app/model/mensagem-aprovada';
import { Pessoa } from 'app/model/pessoa';
import { CampanhaService } from 'app/modules/crm/gestao-campanha/campanha/campanha.service';
import { ChatService } from 'app/modules/crm/gestao-campanha/chat/chat.service';
import { lastValueFrom } from 'rxjs';
import { IniciarConversaRapidaService } from '../iniciar-conversa-rapida.service';

@Component({
  selector: 'app-iniciar-conversa-rapida',
  templateUrl: './iniciar-conversa-rapida.component.html',
  styleUrls: ['./iniciar-conversa-rapida.component.scss'],
})
export class IniciarConversaRapidaComponent implements OnInit {
  pessoa: Pessoa = null;
  conversas: Conversa[] = [];

  numeros = [];

  loading: boolean = false;

  body: any = {
    numero: null,
    mensagem: null,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public _matDialogRef: MatDialogRef<IniciarConversaRapidaComponent>,
    private _utils: FuseUtilsService,
    private _router: Router,
    private _fuseUtilsService: FuseUtilsService,
    private _iniciarConversaService: IniciarConversaRapidaService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (this.data?.pessoa) {
      this.pessoa = this.data.pessoa;
    }

    this._utils.numerosConfigurados$.subscribe((numeros: any[]) => {
      numeros = numeros.map((numero) => {
        return {
          value: numero,
          label: `${numero.numero} - (${numero.nome})`,
        };
      });
      this.numeros = optionsLabelValue(numeros, 'label', 'value');
    });

    this._utils.getWhatsApps().subscribe();
  }

  redirecionar(conversa): void {
    this._router.navigate(['/crm/gestao-campanhas/chat/' + conversa?.pessoa?.id + '/' + conversa?.de]);
    this._matDialogRef.close();
  }

  iniciarConversa(): void {
    this.loading = true;
    console.log({
      ...this.body,
      pessoa: this.pessoa,
    });

    lastValueFrom(
      this._iniciarConversaService.novaConversa({
        ...this.body,
        pessoa: this.pessoa,
      })
    )
      .then((conversa: Conversa) => {
        console.log(conversa);
        this.redirecionar(conversa);
        this._fuseUtilsService.toast('Sucesso', 'A conversa será iniciada em instantes', 'success', true, 3000);
        // this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this._fuseUtilsService.toast('Erro', 'Ocorreu um erro ao iniciar uma nova conversa', 'error', true, 5000);
        this.loading = false;
      });
  }

  voltar() {
    this.pessoa = null;
    this.conversas = [];
  }
}
