
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { FuseCardModule } from '@fuse/components/card';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { SindicatosListModule } from '../sindicatos/sindicatos-list/sindicatos-list.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { filiadosSemImovelRoutes } from './filiados-sem-imovel.routing';
import { AutocompleteSelectModule } from 'app/shared/components/autocomplete-select/autocomplete-select.module';
import { FiliadosSemImovelComponent } from './filiados-sem-imovel.component';
import { FiliadosSemImovelFormComponent } from './filiados-sem-imovel-form/filiados-sem-imovel-form.component';



@NgModule({
  declarations: [FiliadosSemImovelFormComponent],
  imports: [
    AutocompleteSelectModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    FuseCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    RouterModule.forChild(filiadosSemImovelRoutes),
    SharedModule,
    SindicatosListModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatChipsModule
  ]
})
export class FiliadosSemImovelModule { }
