<div class="w-full bg-gray-100 flex items-center justify-center py-4">
  <div class="w-[679px] h-[177px] flex gap-2">
    <div class="w-1/2 h-full bg-white border border-gray-300 flex flex-col p-2 relative">
      <div class="h-full w-full border border-gray-500 rounded-md p-2 flex flex-col justify-between relative z-10">
        <div class="flex items-center justify-between gap-8 pb-2 mb-6">
          <div class="flex items-center gap-4" *ngIf="carteirinha">
            <ng-container *ngIf="carteirinha?.grupo?.imagem?.conteudo; else defaultLogo">
              <img [src]="carteirinha?.grupo?.imagem?.conteudo" alt="Logo Sindicato" class="w-10 h-10 z-10 relative" />
            </ng-container>
            <ng-template #defaultLogo>
              <img src="./../../../../assets/images/banners/senar-simbolo.png" class="w-10 h-10 z-10 relative">
            </ng-template>
            <div>
              <h1 class="text-sm text-green-700">
                {{ carteirinha?.grupo?.nome }}
              </h1>
            </div>
          </div>
          <div class="flex items-center gap-4" *ngIf="carteirinha">
            <ng-container *ngIf="carteirinha?.grupo?.imagem?.conteudo; else defaultLogo">
              <img [src]="carteirinha?.grupo?.imagem?.conteudo" alt="Logo Sindicato" class="w-10 h-10 z-10 relative" />
            </ng-container>
            <ng-template #defaultLogo>
              <img src="./../../../../assets/images/banners/senar-simbolo.png" class="w-10 h-10 z-10 relative">
            </ng-template>
            <p class="text-sm text-green-700 uppercase">
              Identidade Sindical de Produtor Rural
            </p>
          </div>
        </div>
        <div class="flex items-center gap-x-6">
          <div class="flex-1 text-xs text-gray-700 space-y-4">
            <p class="text-base text-green-700 mb-7 font-bold" style="text-transform: uppercase">
              Sindicato dos Produtores Rurais de {{ carteirinha?.grupo.municipio.nome }}
            </p>
            <div>
              <p class="font-bold text-xs text-teal-900">NOME:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1" *ngIf="carteirinha" style="text-transform: uppercase">
                {{ carteirinha?.pessoa?.nome }}
              </div>
            </div>
            <div>
              <p class="font-bold text-xs text-teal-900">CATEGORIA:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1" *ngIf="carteirinha" style="text-transform: uppercase">
                {{ carteirinha?.categoria || 'Não informado' }}
              </div>
            </div>
          </div>
          <div class="rounded-md border border-teal-900 flex items-center justify-center w-30 h-40 mx-auto">
            <ng-container *ngIf="carteirinha?.foto; else defaultAvatar">
              <img [src]="carteirinha?.foto" alt="Imagem da identidade" class="w-full h-full rounded-md object-cover mx-auto" />
            </ng-container>
            <ng-template #defaultAvatar>
              <div class="w-full h-full bg-white flex items-center justify-center rounded-md">
                <mat-icon class="text-gray-500 text-5xl">person</mat-icon>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>    

    <div class="w-1/2 h-full bg-white border border-gray-300 flex flex-col p-2">
      <div class="h-full w-full border border-gray-500 rounded-md p-2 flex flex-col justify-between">
        <div class="text-sm text-gray-700 space-y-2">
          <div class="flex gap-2">
            <div class="flex-1">
              <p class="font-bold whitespace-nowrap text-xs text-teal-900">MATRÍCULA:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha"
                style="text-transform: uppercase">
                {{ carteirinha?.matricula || 'Não informado' }}
              </div>
            </div>
            <div class="flex-1">
              <p class="font-bold whitespace-nowrap text-xs text-teal-900">DATA DA FILIAÇÃO:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha">
                {{ carteirinha?.dataFiliacao | date : 'dd/MM/yyyy' }}
              </div>
            </div>
            <div class="flex-1">
              <p class="font-bold whitespace-nowrap text-xs text-teal-900">DATA DE EMISSÃO:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha">
                {{ carteirinha?.dataEmissao | date : 'dd/MM/yyyy' }}
              </div>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="flex-1">
              <p class="font-bold text-xs text-teal-900">CPF:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha">
                {{ carteirinha?.pessoa.cpf | mask : '000.000.000-00' }}
              </div>
            </div>
            <div class="flex-1">
              <p class="font-bold text-xs text-teal-900">RG:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha">
                {{ carteirinha?.pessoa.identidade | mask : '00.000.000-0' }}
              </div>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="flex-1">
              <p class="font-bold text-xs text-teal-900">MUNICÍPIO:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha"
                style="text-transform: uppercase">
                {{ carteirinha?.grupo?.municipio?.nome }}
              </div>
            </div>
            <div class="flex-[1]">
              <p class="font-bold text-xs text-teal-900">UF:</p>
              <div class="bg-white border border-teal-900 rounded-md px-2 py-1 text-xs" *ngIf="carteirinha"
                style="text-transform: uppercase">
                {{ carteirinha?.grupo?.municipio?.uf }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 relative text-center text-xs text-gray-700">
          <div class="flex flex-col items-center">
            <img src="./../../../../assets/images/banners/Assinatura presidente.png" alt="Assinatura do Presidente"
              class="w-40 h-auto" />
            <div class="w-full border-t border-gray-300 mt-2"></div>
          </div>
          <div class="flex items-center justify-center">
            <img src="./../../../../assets/images/banners/CNA-SENAR-Logo.png" alt="CNA Logo"
              class="w-16 h-16 object-contain" />
            <div>
              <p class="z-10 relative">Rinaldo Tomazini Filho</p>
              <p class="z-10 relative">PRESIDENTE</p>
            </div>
            <img src="./../../../../assets/images/banners/faeg-senar-go.png" alt="FAEG Logo"
              class="w-16 h-16 object-contain" />
          </div>
        </div>
      </div>
    </div>
  </div>