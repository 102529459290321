import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { optionsLabelValue, toast } from 'app/core/utils/utils';
import { Filiacao } from 'app/model/filiacao';
import { GrupoFiliacao } from 'app/model/grupo-filiacao';
import { Pessoa } from 'app/model/pessoa';
import { isEmpty, isUndefined, List } from 'lodash';
import { FiliadosSemImovelService } from '../filiados-sem-imovel.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectPessoaService } from 'app/shared/components/select-pessoa/select-pessoa.service';
import { FuseUtilsService } from '@fuse/services/utils';

@Component({
  selector: 'app-filiados-sem-imovel-form',
  templateUrl: './filiados-sem-imovel-form.component.html',
  styleUrls: ['./filiados-sem-imovel-form.component.scss']
})
export class FiliadosSemImovelFormComponent implements OnInit, AfterViewInit, OnDestroy {
  public filiacao: Filiacao = new Filiacao();
  public grupoFiliacao: GrupoFiliacao = new GrupoFiliacao();
  pessoas = [];
  txtPessoa: string;
  controlPessoa = new FormControl();


  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _dialogRef: MatDialogRef<FiliadosSemImovelFormComponent>,
    private _snackBar: MatSnackBar,
    private _filiadosSemImovelService: FiliadosSemImovelService,
    private _selectPessoaService: SelectPessoaService,
    private _fuseUtilsService: FuseUtilsService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.filiacao) {
      this.filiacao = this.data.filiacao;
      this.pessoas.push(this.filiacao.pessoa)
      this.setPessoa(this.filiacao.pessoa)
    }
    this._filiadosSemImovelService.grupoFiliacao$.subscribe((grupoFiliacao: GrupoFiliacao[]) => {
      this.grupoFiliacao = optionsLabelValue(grupoFiliacao, 'nome');
    })

    this.controlPessoa.valueChanges.subscribe(value => {
      if (!value) {
          this.filiacao.pessoa = null; // ou um objeto vazio, dependendo da sua necessidade
      }
  });

  }

  ngAfterViewInit(): void {
    this._selectPessoaService
      .completePessoa(this.controlPessoa.valueChanges)
      .subscribe((resp) => {
        if (resp?.length) {
          this.pessoas = resp?.slice(0, 20);
          this._changeDetectorRef.markForCheck();
        } else {
          this.pessoas = []
          this._changeDetectorRef.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Métodos públicos
  // -----------------------------------------------------------------------------------------------------
  cancelar() {
    if (!this.loading) {
      this._dialogRef.close();
    }
  }

  validarCampos(): boolean {
    if ( !this.filiacao.grupo.id || isEmpty(this.filiacao.grupo)) {
      toast(this._snackBar, 'Atenção', 'Grupo Filiação não pode estar vazio(a).', 'error', true, 5000);
      return false;
    }
    if (!this.filiacao.codFaegSemImovel || this.filiacao.codFaegSemImovel.length === 0) {
      toast(this._snackBar, 'Atenção', 'Codigo Faeg não pode estar vázio(a).', 'error', true, 5000);
      return false;
    }
    if (isEmpty(this.filiacao.pessoa)) {
      toast(this._snackBar, 'Atenção', 'Pessoa não pode estar vazio(a).', 'error', true, 5000);
      return false;
    }

    return true;
  }

  salvar(): void {
    if (!this.validarCampos()) return;
    this._dialogRef.disableClose = true;
    this.loading = true;
    this.filiacao.situacao = this.filiacao.situacao ? 'ATIVO' : 'INATIVO';
    this._filiadosSemImovelService.salvarFiliadosSemImovel(this.filiacao).subscribe({
      next: () => {
        toast(this._snackBar, 'OK', 'Filiacao salva com Sucesso !', 'success', true, 5000);
        this._dialogRef.disableClose = false;
        this.loading = false;
        this._dialogRef.close(true);
      },
      error: (error) => {
        console.log(error);
        this._dialogRef.disableClose = false;
        this.loading = false;
        toast(this._snackBar, 'Ops!', 'Ocorreu um erro. Tente novamente mais tarde', 'error', true, 5000);
      },
    });
  }

  displayFnPessoa(value: any): string {
    return value && typeof value === 'object' ? (value.nome + ' - ' + value.cpf) : value;
  }

  setPessoa(value) {
    if (value && value.id) {
      this.filiacao.pessoa = value
      this.controlPessoa.patchValue(value.nome + ' - ' + value.cpf)
    }
  }

}
