import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filiacao } from 'app/model/filiacao';
import { GrupoFiliacao } from 'app/model/grupo-filiacao';
import { Pessoa } from 'app/model/pessoa';
import { FotoComponent } from 'app/modules/crm/gestao-campanha/campanha/gerenciador/list/modals/details/foto/foto.component';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiliadosSemImovelService {

  private _filiadosSemImovelList: BehaviorSubject<Filiacao[]> = new BehaviorSubject([]);
  private _listarPessoas: BehaviorSubject<any> = new BehaviorSubject(
    []
  )
  private _listarGrupoFiliacao: BehaviorSubject<GrupoFiliacao[]> = new BehaviorSubject([]);


  get filiacao$(): Observable<Filiacao[]> {
    return this._filiadosSemImovelList.asObservable();
  }

  get grupoFiliacao$(): Observable<GrupoFiliacao[]> {
    return this._listarGrupoFiliacao.asObservable();
  }

  get pessoas$(): Observable<any> {
    return this._listarPessoas.asObservable();
  }

  constructor(private _httpClient: HttpClient) { }

  getListarFiliadosSemImovel(): Observable<any> {
    return this._httpClient.get(`${environment.api}/filiacao/listarFiliadosSemImovel`).pipe(
      catchError((error) => {
        throw error;
      }),
      tap((response: any) => {
        this._filiadosSemImovelList.next(response);
      }),
    );
  }

  salvarFiliadosSemImovel(filiacao: Filiacao): Observable<Filiacao> {
    filiacao.semImovel = true;
    return this._httpClient.post<Filiacao>(`${environment.api}/filiacao`, {filiacao,foto:null}).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  deletarFiliadosSemImovel(id: number): Observable<void> {
    return this._httpClient.delete<void>(`${environment.api}/deletar/${id}`).pipe(
      catchError((error) => {
        throw error
      }),
      tap(() => { }),
    );
  }

  getListarPessoas(): Observable<Pessoa[]> {
    return this._httpClient.get(`${environment.api}/listarPessoasLimitado`).pipe(
      catchError((error) => {
        this._listarPessoas.next([]);
        return error;
      }),
      tap((pessoa: Pessoa[]) => {
        this._listarPessoas.next(pessoa);
      }),
    );
  }

  getListarGruposFiliados(): Observable<GrupoFiliacao[]> {
    return this._httpClient.get(`${environment.api}/grupoFiliacao`).pipe(
      catchError((error) => {
        this._listarGrupoFiliacao.next([]);
        return error;
      }),
      tap((grupo: GrupoFiliacao[]) => {
        this._listarGrupoFiliacao.next(grupo);
      }),
    );
  }


}
