import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Associado } from 'app/model/associado';
import { AssociadoService } from '../associados.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectSindicatoService } from 'app/shared/components/select-sindicato/select-sindicato.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrupoFiliacao } from 'app/model/grupo-filiacao';
import { toast } from 'app/core/utils/utils';

interface AssociadosFormData {
  isEditMode: boolean;
  associadosId?: string;
  grupo?: GrupoFiliacao;
}

@Component({
  selector: 'app-associados-form',
  templateUrl: './associados-form.component.html',
  styleUrls: ['./associados-form.component.scss']
})
export class AssociadosFormComponent implements OnInit {

  associadosForm: FormGroup;
  isEditMode: boolean = false;
  grupo: GrupoFiliacao;
  associadosId?: string;
  errorMessage: string = '';
  sindicatoSelecionadoNome: string = '';
  pessoa: any = {};
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private associadosService: AssociadoService,
    private sindicatoService: SelectSindicatoService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AssociadosFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssociadosFormData
  ) {

    const mesVencimentoValidators = this.data.grupo?.mensal ? [] : [Validators.required];
    this.associadosForm = this.fb.group({
      grupo: ['', Validators.required],
      dataAssociacao: [new Date(), Validators.required],
      cpf: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]],
      telefone: [''],
      celular: [''],
      ativo: [true, Validators.required],
      adimplente: [true, Validators.required],
      diaVencimentoCobranca: ['', Validators.required],
      mesVencimentoCobranca: ['', mesVencimentoValidators],
      valorContribuicao: [''],
      tempoExercicioAtividade: ['', Validators.required],
      outrasOcupacoes: ['']
    });
  }

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.associadosId = this.data.associadosId;

    if (this.data.grupo && 'valor' in this.data.grupo) {
      this.associadosForm.patchValue({
        valorContribuicao: this.data.grupo.valor || null
      });

    }

    if (!this.isEditMode) {
      this.setDefaultDate();
    }

    const sindicatoSelecionado = this.sindicatoService.sindicatoSelecionado;
    if (sindicatoSelecionado) {
      this.associadosForm.patchValue({ grupo: sindicatoSelecionado.id });
      this.sindicatoSelecionadoNome = sindicatoSelecionado.nome;
    }

    if (this.isEditMode && this.associadosId) {
      this.loadAssociado(this.associadosId);
    }
  }

  setDefaultDate(): void {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    this.associadosForm.patchValue({
      diaVencimentoCobranca: day,
      mesVencimentoCobranca: this.data.grupo.mensal ? null : month
    });
  }

  loadAssociado(id: string): void {
    this.associadosService.getById(id).subscribe({
      next: (data) => {
        this.associadosForm.patchValue({
          dataAssociacao: data.dataAssociacao,
          cpf: data.pessoa.cpf,
          telefone: data.pessoa.telefone,
          celular: data.pessoa.celular,
          ativo: data.ativo,
          adimplente: data.adimplente,
          diaVencimentoCobranca: data.diaVencimentoCobranca,
          mesVencimentoCobranca: data.mesVencimentoCobranca,
          valorContribuicao: data.grupo.valor,
          tempoExercicioAtividade: data.tempoExercicioAtividade,
          outrasOcupacoes: data.outrasOcupacoes
        });
        this.pessoa = data.pessoa;
      },
      error: (err) => this.errorMessage = err
    });
  }

  pesquisarPessoaPorCPF(): void {
    const cpf = this.associadosForm.get('cpf')?.value;

    if (!cpf || this.validacaoCPF(cpf)) {
      this._snackBar.open('CPF inválido.', 'Atenção!', { duration: 5000 });
      return;
    }

    this.loading = true;

    this.associadosService.buscarPessoaPorCPF(cpf).subscribe({
      next: (pessoa: any) => {
        if (pessoa) {
          this.pessoa = pessoa;
          this.associadosForm.patchValue({
            telefone: pessoa.telefone,
            celular: pessoa.celular,
            cpf: pessoa.cpf
          });
        } else {
          this._snackBar.open('Nenhuma pessoa encontrada.', 'Erro', { duration: 5000 });
        }
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.loading = false;
      }
    });
  }

  validacaoCPF(cpf: string): boolean {
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return true;
    }

    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return true;

    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(10))) return true;

    return false;
  }

  enforceDayRange(event: KeyboardEvent): void {
    const input = (event.target as HTMLInputElement);
    const value = parseInt(input.value + event.key);

    if (value < 1 || value > 31) {
      event.preventDefault();
    }
  }

  enforceMonthRange(event: KeyboardEvent): void {
    const input = (event.target as HTMLInputElement);
    const value = parseInt(input.value + event.key);

    if (value < 1 || value > 12) {
      event.preventDefault();
    }
  }


  onSubmit(): void {
    if (this.associadosForm.invalid) {
      return;
    }

    const associadoId = this.isEditMode ? Number(this.associadosId) : undefined;

    const associado: Associado = {
      id: associadoId,
      grupo: this.associadosForm.get('grupo')?.value,
      dataAssociacao: this.associadosForm.get('dataAssociacao')?.value,
      pessoa: { id: this.pessoa.id } as any,
      ativo: this.associadosForm.get('ativo')?.value,
      adimplente: this.associadosForm.get('adimplente')?.value,
      diaVencimentoCobranca: this.associadosForm.get('diaVencimentoCobranca')?.value,
      mesVencimentoCobranca: this.associadosForm.get('mesVencimentoCobranca')?.value,
      valorContribuicao: this.associadosForm.get('valorContribuicao')?.value,
      tempoExercicioAtividade: this.associadosForm.get('tempoExercicioAtividade')?.value,
      outrasOcupacoes: this.associadosForm.get('outrasOcupacoes')?.value,
      criadoEm: this.isEditMode ? this.associadosForm.get('criadoEm')?.value : new Date(),
      alteradoEm: new Date(),
      dataBase: null
    };

    const associadoPayload = {
      ...associado,
      grupoId: associado.grupo,
    };
    delete associadoPayload.grupo;

    if (this.isEditMode && this.associadosId) {
      this.associadosService.update(this.associadosId, associadoPayload).subscribe({
        next: () => {
          this.dialogRef.close(true);
          toast(
            this._snackBar,
            'Salvo!',
            'O associado foi alterado com sucesso.',
            'success',
            true,
            5000
          );
        },
        error: (err) => this.errorMessage = err
      });
    } else {
      this.associadosService.create(associadoPayload).subscribe({
        next: () => {
          this.dialogRef.close(true);
          toast(
            this._snackBar,
            'Salvo!',
            'O associado foi salvo com sucesso.',
            'success',
            true,
            5000
          );
        },
        error: (err) => this.errorMessage = err
      });
    }
  }

}
