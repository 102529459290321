import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestao-sindical',
  templateUrl: './gestao-sindical.component.html',
  styleUrls: ['./gestao-sindical.component.scss']
})
export class GestaoSindicalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
