import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseCardModule } from '@fuse/components/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'app/shared/shared.module';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseFindByKeyPipeModule } from '@fuse/pipes/find-by-key';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ContaBancariaComponent } from './conta-bancaria/conta-bancaria.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { FormularioComponent } from './crm/gestao-campanha/campanha/formulario/formulario.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TabelaPadraoComponent } from 'app/shared/components/tabela-padrao/tabela-padrao.component';
import { CursosRmComponent } from 'app/shared/components/cursos-rm/cursos-rm.component';
import { PessoaFormComponent } from 'app/shared/components/pessoa-form/pessoa-form.component';
import { MicroRegiaoComponent } from 'app/shared/components/micro-regiao/micro-regiao.component';
import { FormArquivoComponent } from 'app/shared/components/form-arquivo/form-arquivo.component';
import { LimiteMaximoDirective } from 'app/shared/directives/limite-maximo.directive';
import { MatRadioModule } from '@angular/material/radio';
import { AutocompleteComponent } from 'app/shared/components/autocomplete/autocomplete.component';
import { UploadMidiaComponent } from 'app/shared/components/upload-midia/upload-midia.component';
import { NovoChatComponent } from './crm/gestao-campanha/chat/chats/novo-chat/novo-chat.component';
import { AnexarCdnComponent } from 'app/shared/components/AnexarCdn/anexar-cdn.component';
import { noObjeDirective } from 'app/shared/directives/no-object.directive';
import { ExibirEnumDirective } from 'app/shared/directives/exibir-enum.directive';
import { ConversationComponent } from './crm/gestao-campanha/chat/conversation/conversation.component';
import { PipeModule } from 'app/pipe/pipe.module';
import { ConversaMidiasComponent } from './crm/gestao-campanha/chat/conversation/conversa-midias/conversa-midias.component';
import { VerStatusComponent } from './crm/gestao-campanha/chat/conversation/ver-status/ver-status.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GraficoDinamicaComponent } from './crm/gestao-campanha/campanha/gerenciador/list/modals/fluxo/grafico-dinamica/grafico-dinamica.component';
import { QrcodeComponent } from 'app/shared/components/qrcode/qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactInfoComponent } from './crm/gestao-campanha/chat/contact-info/contact-info.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UploadArchiveComponent } from 'app/shared/components/upload-archive/upload-archive.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutocompleteSelectModule } from 'app/shared/components/autocomplete-select/autocomplete-select.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    AutocompleteSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
    MatProgressBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    FuseFindByKeyPipeModule,
    SharedModule,
    MatTabsModule,
    FuseCardModule,
    MatDividerModule,
    MatMenuModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatExpansionModule,
    NgxFileDropModule,
    MatRadioModule,
    PipeModule,
    NgApexchartsModule,
    QRCodeModule,
    MatDialogModule,
    MatDatepickerModule,
    RouterModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    DragDropModule
  ],
  exports: [
    MatOptionModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    NgxMaskModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    FuseFindByKeyPipeModule,
    SharedModule,
    MatTabsModule,
    FuseCardModule,
    MatDividerModule,
    MatMenuModule,
    ContaBancariaComponent,
    MatCheckboxModule,
    MatAutocompleteModule,
    TabelaPadraoComponent,
    PessoaFormComponent,
    CursosRmComponent,
    MicroRegiaoComponent,
    FormularioComponent,
    FormArquivoComponent,
    LimiteMaximoDirective,
    AutocompleteComponent,
    UploadMidiaComponent,
    AnexarCdnComponent,
    noObjeDirective,
    ExibirEnumDirective,
    ConversationComponent,
    ConversaMidiasComponent,
    VerStatusComponent,
    GraficoDinamicaComponent,
    ContactInfoComponent,
    PerfectScrollbarModule,
    UploadArchiveComponent,
  ],
  declarations: [
    ContaBancariaComponent,
    TabelaPadraoComponent,
    PessoaFormComponent,
    CursosRmComponent,
    MicroRegiaoComponent,
    FormularioComponent,
    FormArquivoComponent,
    LimiteMaximoDirective,
    AutocompleteComponent,
    UploadMidiaComponent,
    NovoChatComponent,
    AnexarCdnComponent,
    noObjeDirective,
    ExibirEnumDirective,
    ConversationComponent,
    ConversaMidiasComponent,
    VerStatusComponent,
    GraficoDinamicaComponent,
    QrcodeComponent,
    ContactInfoComponent,
    UploadArchiveComponent,
  ],
})
export class CenterModuleModule {}
