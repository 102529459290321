import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Conversa } from 'app/model/conversa';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IniciarConversaRapidaService {

  constructor(private _httpClient: HttpClient) { }

  novaConversa(body): Observable<Conversa> {
    return this._httpClient.post<Conversa>(`${environment.api}/conversa/iniciarConversaSemCampanha`, body );
  }
}
