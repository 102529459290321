import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Filiacao } from 'app/model/filiacao';
import { Subject } from 'rxjs';
import { FiliadosSemImovelService } from './filiados-sem-imovel.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import { FiliadosSemImovelFormComponent } from './filiados-sem-imovel-form/filiados-sem-imovel-form.component';

@Component({
  selector: 'app-filiados-sem-imovel',
  templateUrl: './filiados-sem-imovel.component.html',
  styleUrls: ['./filiados-sem-imovel.component.scss']
})
export class FiliadosSemImovelComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) public filiadosSemImovelPaginator: MatPaginator;
  @ViewChild(MatSort) public filiadosSemImovelMatSort: MatSort;

  public filiadosSemImovelDataSource: MatTableDataSource<Filiacao> = new MatTableDataSource();
  public filiadosSemImovelTableColumns: string[] = ['grupo_filiacao', 'codigo', 'nome_pessoa', 'cpf_pessoa', 'acoes'];

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  public filiadosSemImovel: Filiacao[] = [];

  constructor(
    private _fuseConfirmationService: FuseConfirmationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _filiadosSemImovelService: FiliadosSemImovelService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Métodos de ciclo de vida dos componentes
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._filiadosSemImovelService.filiacao$.subscribe((filiacao: Filiacao[]) => {
      if (filiacao.length > 0) {
        this.filiadosSemImovelDataSource.data = filiacao;
        this.filiadosSemImovel = filiacao;
        this.filiadosSemImovelDataSource.paginator = this.filiadosSemImovelPaginator;
        this.filiadosSemImovelDataSource.sort = this.filiadosSemImovelMatSort;
        this._changeDetectorRef.detectChanges();
      }

      this.filiadosSemImovelDataSource.data;
    });
  }

  ngAfterViewInit(): void {
    // Implementar lógica necessária, se houver
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Métodos públicos
  // -----------------------------------------------------------------------------------------------------

  filtrarFiliadosSemImovel(nome: string): void {
    let filiadosArray = [];
    if (nome) {
      this.filiadosSemImovelDataSource.data = this.filiadosSemImovel.filter((filiacao: Filiacao) => {
        if (
          filiacao?.pessoa.nome?.toLowerCase().includes(nome.toLowerCase()) ||
          filiacao?.grupo.nome?.toLowerCase().includes(nome.toLowerCase())
        ) {
          return filiadosArray.push(filiacao);
        }
      });
    } else {
      this.filiadosSemImovelDataSource.data = cloneDeep(this.filiadosSemImovel);
    }
  }

  criarFiliadoSemImovel(filiacao?: Filiacao): void {
    const dialogRef = this._dialog.open(FiliadosSemImovelFormComponent, {
      width: '90%',
      height: 'auto',
      data: {
        filiacao,
      },
    });
    dialogRef.afterClosed().subscribe((resultado: boolean) => {
      if (resultado) {
        this._filiadosSemImovelService.getListarFiliadosSemImovel().subscribe({});
      }
    });
  }

  deletarFiliadosSemImovel(filiado: Filiacao): void {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Atenção!',
      message: `Você realmente quer excluir '${filiado.grupo.nome} filiado a(o) '${filiado.pessoa.nome}'?`,
      icon: {
        show: true,
      },
      actions: {
        confirm: {
          label: 'Sim',
          color: 'primary',
        },
        cancel: {
          label: 'Não',
        },
      },
    });


    // confirmation.afterClosed().subscribe((result) => {
    //   if (result === 'confirmed') {
    //     this._filiadosSemImovelService.deletarFiliadosSemImovel(filiado.id).subscribe({
    //       next: () => {
    //         toast(this._snackBar, 'Sucesso', 'O email foi excluído com sucesso.', 'success', true, 5000);
    //         this._emailListenerService.getlistarEmails().subscribe({});
    //       },
    //       error: (error) => {
    //         console.error(error);
    //         toast(this._snackBar, 'Ops!', 'Ocorreu um erro ao excluír o email.', 'error', true, 5000);
    //       },
    //     });
    //   }
    // });
  }
}
