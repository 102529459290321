import { NgModule } from '@angular/core';
import { IniciarConversaRapidaComponent } from './iniciar-conversa-rapida/iniciar-conversa-rapida.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { AutocompleteSelectModule } from '../autocomplete-select/autocomplete-select.module';
import { SharedModule } from 'app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IonicModule } from '@ionic/angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PipeModule } from 'app/pipe/pipe.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CenterModuleModule } from 'app/modules/center-module.module';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { AlertModule } from 'app/modules/alert/alert.module';

@NgModule({
  declarations: [IniciarConversaRapidaComponent],
  imports: [
    AlertModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatTooltipModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    IonicModule,
    MatDialogModule,
    MatSlideToggleModule,
    PipeModule,
    MatChipsModule,
    MatButtonToggleModule,
    CenterModuleModule,
    NgxImageZoomModule,
    AutocompleteSelectModule
  ],
  exports: [IniciarConversaRapidaComponent],
})
export class IniciarConversaRapidaModule {}
