import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Filiacao } from 'app/model/filiacao';
import { Observable, of } from 'rxjs';
import { FiliadosSemImovelService } from './filiados-sem-imovel.service';
import { GrupoFiliacao } from 'app/model/grupo-filiacao';
import { Pessoa } from 'app/model/pessoa';

@Injectable({
  providedIn: 'root'
})
export class FiliadosSemImovelResolver implements Resolve<Filiacao[]> {
  /**
  * Constructor
  */
  constructor(private readonly _filiadosSemImovelService: FiliadosSemImovelService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Filiacao[]> {
    return this._filiadosSemImovelService.getListarFiliadosSemImovel();
  }
}

@Injectable({
  providedIn: 'root',
})
export class FiliadosGrupoResolver implements Resolve<GrupoFiliacao[]> {
  /**
   * Constructor
   */
  constructor(private readonly _filiadosSemImovelService: FiliadosSemImovelService) { }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GrupoFiliacao[]> {
    return this._filiadosSemImovelService.getListarGruposFiliados();
  }
}

@Injectable({
  providedIn: 'root',
})
export class FiliadosPessoaResolver implements Resolve<Pessoa[]> {
  /**
   * Constructor
   */
  constructor(private readonly _filiadosSemImovelService: FiliadosSemImovelService) { }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Pessoa[]> {
    return this._filiadosSemImovelService.getListarPessoas();
  }
}



