import { Route } from '@angular/router';
import { AssociadosListComponent } from './associados-list/associados-list.component';
import { CarteirinhaComponent } from '../carteirinha/carteirinha.component';

export const AssociadosRoute: Route[] = [
  {
    path: '',
    component: AssociadosListComponent
  },
  {
    path: 'carteirinha/:id',
    component: CarteirinhaComponent,
  },
];
