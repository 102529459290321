<div class="flex flex-col flex-auto w-full">
  <div class="flex flex-wrap w-full max-w-screen-xl mx-auto py-6 md:p-8">
    <div class="mt-4 sm:mt-4 md:mt-4 lg:mt-4 w-full">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="my-1">
            <mat-panel-title>
              <mat-icon [svgIcon]="'filter_alt'"></mat-icon>
              Filtros Avançados
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full"
          >
            <div>
              <mat-form-field class="w-full">
                <mat-label>Sindicato</mat-label>
                <input
                  matInput
                  aria-label="Filiacao"
                  [matAutocomplete]="autoComplete"
                  name="filiacao"
                  #sindicato
                  [(ngModel)]="txtSindicato"
                  (keyup)="filtrarSindicatosPorUsuario(sindicato.value)"
                />

                <mat-autocomplete
                  matNativeControl
                  #autoComplete="matAutocomplete"
                  name="filiacao"
                >
                  <mat-option
                    *ngFor="let sindicato of sindicatosPorUsuariosFiltrados"
                    [value]="sindicato.nome"
                    (click)="setSindicato(sindicato)"
                  >
                    <span>{{ sindicato.nome }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="flex flex-col">
              <mat-checkbox
                [color]="'primary'"
                class="font-semibold"
                [(ngModel)]="filtro.filiados"
                >Filiados CNA
              </mat-checkbox>
              <mat-checkbox
                [color]="'primary'"
                class="font-semibold"
                [(ngModel)]="filtro.associados"
                >Filiados Sindicato
              </mat-checkbox>
              <mat-checkbox
                [color]="'primary'"
                class="font-semibold"
                [(ngModel)]="filtro.relacionadoMunicipio"
              >
                Relacionado com município</mat-checkbox
              >
            </div>
          </div>
          <div class="flex flex-row justify-end w-full">
            <div
              class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 w-full"
            >
              <div
                class="flex flex-row justify-center md:justify-end lg:justify-end items-center gap-4 w-full"
              >
                <button mat-flat-button [color]="'primary'" (click)="filtrar()">
                  <mat-icon [svgIcon]="'filter_alt'"></mat-icon>
                  Filtrar
                </button>

                <button mat-stroked-button (click)="limparCampos()">
                  <mat-icon [svgIcon]="'clear'"></mat-icon>
                  Limpar filtro
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="mt-4 sm:mt-4 md:mt-4 lg:mt-4 w-full">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="my-1">
            <mat-panel-title>
              <mat-icon>list</mat-icon>
              Opções
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="flex flex-col">
            <h2 class="text-xl font-semibold tracking-tight leading-8">
              Exibir ou ocultar colunas
            </h2>
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[0]"
              (ngModelChange)="setVisible()"
              >Nome</mat-checkbox
            >
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[1]"
              (ngModelChange)="setVisible()"
              >Telefone</mat-checkbox
            >
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[2]"
              (ngModelChange)="setVisible()"
              >CPF</mat-checkbox
            >
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[3]"
              (ngModelChange)="setVisible()"
              >Endereço</mat-checkbox
            >
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[4]"
              (ngModelChange)="setVisible()"
              >Área</mat-checkbox
            >
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[5]"
              (ngModelChange)="setVisible()"
              >Cursos</mat-checkbox
            >
            <mat-checkbox
              [color]="'primary'"
              class="font-semibold"
              [(ngModel)]="visible[6]"
              (ngModelChange)="setVisible()"
              >Etiquetas</mat-checkbox
            >
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div
      class="flex justify-end w-full my-4"
      *ngIf="
        filtro.associados && !(filtro.filiados || filtro.relacionadoMunicipio)
      "
    >
      <button
        mat-flat-button
        [color]="'primary'"
        (click)="exportarConsulta('excel')"
      >
        <mat-icon svgIcon="feather:file-text"></mat-icon>
        <span class="ml-2">Exportar excel</span>
      </button>
    </div>

    <div
      class="overflow-x-auto w-full"
      [ngStyle]="{
        'margin-top': !(
          filtro.associados && !(filtro.filiados || filtro.relacionadoMunicipio)
        )
          ? '16px'
          : ''
      }"
    >
      <table
        matSort
        mat-table
        class="w-full"
        #cadastrosPessoasTable
        [dataSource]="cadastrosPessoasDataSource"
      >
        <!-- Nome -->
        <ng-container matColumnDef="nome">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              {{
                cadastrosPessoas?.pessoa?.nome
                  ? cadastrosPessoas.pessoa.nome
                  : "Não informado"
              }}
            </span>
          </td>
        </ng-container>

        <!-- Telefone -->
        <ng-container matColumnDef="telefone">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Telefone</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              {{
                cadastrosPessoas?.pessoa?.telefone?.length === 11
                  ? (cadastrosPessoas.pessoa.telefone
                    | mask : "(00) 0 0000-0000")
                  : (cadastrosPessoas.pessoa.telefone | mask : "(00) 0000-0000")
              }}
            </span>
          </td>
        </ng-container>

        <!-- CPF -->
        <ng-container matColumnDef="cpf">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>CPF</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              {{
                cadastrosPessoas?.pessoa?.cpf
                  ? (cadastrosPessoas.pessoa.cpf | mask : "000.000.000-00")
                  : "Não informado"
              }}
            </span>
          </td>
        </ng-container>

        <!-- Endereço -->
        <ng-container matColumnDef="endereco">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Endereço</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              {{
                cadastrosPessoas?.pessoa?.endereco
                  ? cadastrosPessoas.pessoa.endereco
                  : "Não informado"
              }}
            </span>
          </td>
        </ng-container>

        <!-- Exportar -->
        <ng-container matColumnDef="exportar">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Ficha Associado
          </th>
          <td mat-cell *matCellDef="let exportar">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              <button
                color="primary"
                mat-icon-button
                *ngIf="exportar?.associado"
                (click)="exportarFicha(exportar)"
                matTooltip="Exportar ficha de associação"
              >
                <mat-icon>picture_as_pdf</mat-icon>
              </button>
            </span>
          </td>
        </ng-container>

        <!-- Área -->
        <ng-container matColumnDef="area">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Área (ha)</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              {{
                cadastrosPessoas?.area
                  ? cadastrosPessoas.area + " (ha)"
                  : "Não informado"
              }}
            </span>
          </td>
        </ng-container>

        <!-- Cursos -->
        <ng-container matColumnDef="cursos">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Cursos</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              <button
                [color]="'primary'"
                mat-flat-button
                matBadge="{{ cadastrosPessoas?.cursos.length }}"
                (click)="listarCursos(cadastrosPessoas.cursos)"
              >
                Cursos
              </button>
            </span>
          </td>
        </ng-container>

        <!-- Etiquetas -->
        <ng-container matColumnDef="etiquetas">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Etiquetas</th>
          <td mat-cell *matCellDef="let cadastrosPessoas">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
            >
              <button
                [color]="'primary'"
                mat-flat-button
                matBadge="{{ cadastrosPessoas?.etiquetas.length }}"
                (click)="listarEtiquetas(cadastrosPessoas.etiquetas)"
              >
                Etiquetas
              </button>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="cadastrosPessoasTableColumns"></tr>
        <tr
          class="order-row h-16"
          mat-row
          *matRowDef="let row; columns: cadastrosPessoasTableColumns"
        ></tr>
      </table>
      <div
        *ngIf="!cadastrosPessoasDataSource.data.length"
        class="p-5 bg-white border border-t-0 border-l-0 border-r-0 border-b-1 w-full"
      >
        <label>Nenhum dado encontrado.</label>
      </div>
    </div>
    <mat-paginator
      class="w-full"
      showFirstLastButtons
      #cadastrosPessoasPaginator
      aria-label="Selecione a página"
      [pageSizeOptions]="[10, 25, 50, 100]"
    >
    </mat-paginator>
  </div>
</div>
