<div class="grid grid-cols-1 w-full">
  <autocomplete-select [title]="'Número'" [options]="numeros" [(ngModel)]="body.numero"></autocomplete-select>

  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Mensagem</mat-label>
    <input type="text" matInput [(ngModel)]="body.mensagem">
  </mat-form-field>

  <button class="mt-2" mat-stroked-button color="warn" (click)="voltar()">Voltar</button>
  <button class="mt-4" mat-flat-button color="primary" (click)="iniciarConversa()">Iniciar conversa</button>
</div>
