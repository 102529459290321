import { Etiqueta } from './etiqueta';
import { FiliacaoPropriedade } from './filiacao-propriedade';
import { GrupoFiliacao } from './grupo-filiacao';
import { Pessoa } from './pessoa';


export class Filiacao {

    id;
    grupo: GrupoFiliacao;
    data;
    dataAssociacao;
    situacao;
    situacaoAssociacao;
    receitaId;
    observacao;
    observacaoAssociacao;
    pessoa: Pessoa;
    propriedades: FiliacaoPropriedade[];
    semImovel: Boolean;
    codFaegSemImovel: string;
    codFaeg : number;

    

    constructor(filiacao?: Filiacao) {
        if (filiacao) {
            this.id = filiacao.id;
            this.grupo = new GrupoFiliacao(filiacao.grupo);
            if (filiacao.data) {
                this.data = new Date(filiacao.data);
                this.data.setDate(this.data.getUTCDate());
            }
            if (filiacao.dataAssociacao) {
                this.dataAssociacao = new Date(filiacao.dataAssociacao);
                this.dataAssociacao.setDate(this.dataAssociacao.getUTCDate());
            }
            this.situacao = filiacao.situacao;
            this.situacaoAssociacao = filiacao.situacaoAssociacao;
            this.receitaId = filiacao.receitaId;
            this.observacao = filiacao.observacao;
            this.observacaoAssociacao = filiacao.observacaoAssociacao;
            this.pessoa = new Pessoa(filiacao.pessoa);
            this.propriedades = filiacao.propriedades ? [...filiacao.propriedades] : [];
            this.codFaegSemImovel = filiacao.codFaegSemImovel
            this.semImovel = filiacao.semImovel;
            this.codFaeg = filiacao.codFaeg

            
        } else {
            this.id = null;
            this.grupo = new GrupoFiliacao();
            this.data = '';
            this.dataAssociacao = '';
            this.situacao = null;
            this.situacaoAssociacao = null;
            this.receitaId = '';
            this.observacao = '';
            this.observacaoAssociacao = '';
            this.pessoa = new Pessoa();
            this.propriedades = [];
            this.codFaegSemImovel = '';
            this.semImovel = null;
            this.codFaeg = null;

        }
    }
}