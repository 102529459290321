import { NgModule } from '@angular/core';
import { AssociadosFormComponent } from './associados-form/associados-form.component';
import { AssociadosListComponent } from './associados-list/associados-list.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'app/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { AssociadosRoute } from './associados.routing';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FuseCardModule } from '@fuse/components/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CriarPagamentoFormComponent } from './criar-pagamento-form/criar-pagamento-form.component';
import { CarteirinhaComponent } from '../carteirinha/carteirinha.component';
import { IniciarConversaRapidaModule } from 'app/shared/components/iniciar-conversa-rapida/iniciar-conversa-rapida.module';

@NgModule({
  declarations: [AssociadosFormComponent, AssociadosListComponent, CriarPagamentoFormComponent, CarteirinhaComponent],
  imports: [
    RouterModule.forChild(AssociadosRoute),
    MatPaginatorModule,
    FuseCardModule,
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    IniciarConversaRapidaModule
  ],
  exports: [
    AssociadosListComponent, AssociadosFormComponent, CriarPagamentoFormComponent, CarteirinhaComponent
  ]
})
export class AssociadosModule { }
