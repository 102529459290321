import { Pessoa } from '../model/pessoa';

export class CadastrosPessoasDto {
  public cursos: [];
  public area: number;
  public etiquetas: [];
  public associado: any;
  public pessoa: Pessoa;

  constructor(cadastrosPessoasDto?: CadastrosPessoasDto) {
    if (cadastrosPessoasDto) {
      this.pessoa = cadastrosPessoasDto.pessoa;
      this.area = cadastrosPessoasDto.area;
      this.cursos = cadastrosPessoasDto.cursos;
      this.etiquetas = cadastrosPessoasDto.etiquetas;
      this.associado = cadastrosPessoasDto.associado;
    } else {
      this.pessoa = new Pessoa();
      this.area = null;
      this.cursos = [];
      this.etiquetas = [];
      this.associado = null;
    }
  }
}
