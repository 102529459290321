import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, catchError, debounceTime, map, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectPessoaService {
  constructor(private _httpClient: HttpClient) { }

  //  ONDE
  //   constructor(this._pessoas : SelectPessoaService)
  //
  // ngAfterViewInit(): void {
  //   this._pessoas
  //     .completePessoa(this.controlPessoa.valueChanges)
  //     .subscribe((resp) => {
  //       if (resp?.length) {
  //         this.pessoas = resp?.slice(0, 20);
  //
  //         this._changeDetectorRef.markForCheck();
  //       }
  //     });
  // }

  // ===================== ACIMA EXEMPLO DE IMPLEMENTAÇÃO ================

  ultimo = '';
  completePessoa(input: Observable<String>): Observable<any> {
    return input.pipe(
      debounceTime(600),
      map((value) => {
        if (typeof value === 'string' && !value.includes(' - ')) {
          return value;
        } else {
          return '';
        }
      }),
      switchMap((texto: any) => {

        if (typeof texto === 'string') {
          if (texto && this.ultimo !== texto?.trim() && texto?.length > 3) {
            this.ultimo = texto?.trim();
            return this.pessoas(texto).pipe(take(1));
          } else {
            this.ultimo = texto?.trim();

            return of([]);
          }
        }
      }),
      catchError(() => of(false))
    );
  }

  pessoas(text: any) {
    return this._httpClient.get(`${environment.api}/pessoa/findByNomeOrCpfOrCelularOrTelefone/${text}`);
  }
}
