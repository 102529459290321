import { Route } from '@angular/router';

import { CadastrosPessoasComponent } from './cadastros-pessoas/cadastros-pessoas.component';
import { GestaoSindicalComponent } from './gestao-sindical.component';

export const gestaoSindicatoRoutes: Route[] = [
  {
    path: '',
    component: GestaoSindicalComponent,
    children: [
      {
        path: 'cadastros-pessoas',
        component: CadastrosPessoasComponent,
      },
    ],
  },
  {
    path: 'sindicatos',
    loadChildren: () =>
      import('app/modules/gestao-sindical/sindicatos/sindicatos.module').then(
        (m) => m.SindicatosModule
      ),
    resolve: {},
  },
  {
    path: 'associados',
    loadChildren: () =>
      import('app/modules/gestao-sindical/associados/associados.module').then(
        (m) => m.AssociadosModule
      ),
    resolve: {},
  },
  {
    path: 'produtores-assistidos',
    loadChildren: () =>
      import('app/modules/gestao-sindical/produtores-assistidos/produtores-assistidos.module').then(
        (m) => m.ProdutoresAssistidosModule
      ),
    resolve: {},
  },
  {
    path: 'sessao',
    loadChildren: () =>
      import('app/modules/gestao-sindical/sessao/sessao.module').then(
        (m) => m.SessaoModule
      ),
    resolve: {},
  },
  {
    path: 'filiados-sem-imovel',
    loadChildren: () =>
      import('app/modules/gestao-sindical/filiados-sem-imovel/filiados-sem-imovel.module').then(
        (m) => m.FiliadosSemImovelModule
      ),
    resolve: {},
  },
  {
    path: 'comissoes',
    loadChildren: () =>
      import('app/modules/gestao-sindical/comissoes/comissoes.module').then(
        (m) => m.ComissoesModule
      ),
    resolve: {},
  },
  {
    path: 'eventos',
    loadChildren: () =>
      import('app/modules/gestao-sindical/evento-sistema/evento-sistema.module').then(
        (m) => m.EventoSistemaModule
      ),
    resolve: {},
  },

  {
    path: 'alunos',
    loadChildren: () =>
      import('app/modules/gestao-sindical/alunos/alunos.module').then(
        (m) => m.AlunosModule
      ),
    resolve: {},
  },
  
];
