import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

export interface Aluno {
  id: string;
  nome: string;
  cpf: string;
  telefone: string;
  celular: string;
  curso: string; 
}


@Injectable({
  providedIn: 'root',
})
export class AlunosService {
  private _alunos: BehaviorSubject<Aluno[]> = new BehaviorSubject([]);

  constructor(private _httpClient: HttpClient) {}

  get alunos$(): Observable<Aluno[]> {
    return this._alunos.asObservable();
  }

  listarAlunosPorSindicato(sindicatoId: string): Observable<Aluno[]> {
    return this._httpClient
      .get<Aluno[]>(`${environment.api}/eventos-participantes/sindicato/${sindicatoId}/alunos`)
      .pipe(
        tap((alunos: Aluno[]) => this._alunos.next(alunos)),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Ocorreu um erro:', error);
    return throwError('Algo deu errado. Por favor, tente novamente mais tarde.');
  }

  getTotalDeAlunos(sindicatoId: string): Observable<number> {
    return this._httpClient.get<number>(`${environment.api}/eventos-participantes/sindicato/${sindicatoId}/total`);

  }
  

}
