<div class="grid grid-cols-1 gap-8 w-full">
  <mat-card class="mat-elevation-z4" style="margin: 20px;">
    <mat-card-title style="padding: 16px; font-size: 24px; text-align: center;">
      Associados
    </mat-card-title>

    <div class="flex items-center justify-between px-4 py-4">

      <div class="flex items-center gap-4 w-full">
        <form [formGroup]="filterForm" class="flex items-center w-full">
          <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded fuse-mat-dense w-full mr-4" floatLabel="always">
            <button mat-icon-button matPrefix>
              <mat-icon>search</mat-icon>
            </button>
            <input matInput formControlName="nome" placeholder="Buscar por nome ou CPF" />
          </mat-form-field>


          <button mat-flat-button color="primary" (click)="createAssociados()">
            Novo
          </button>

          <button mat-raised-button color="accent" (click)="refreshAssociados()" class="ml-4">
            Atualizar Lista
          </button>
        </form>
      </div>

    </div>

    <div class="px-4 py-4">
      <div class="flex items-center justify-between gap-4">
        <div class="flex items-center gap-4">
          <mat-checkbox color="primary" formControlName="ativo"
            (change)="filterForm.controls['ativo'].setValue($event.checked)" checked>
            <span>Ativo</span>
          </mat-checkbox>

          <mat-checkbox color="primary" formControlName="adimplente"
            (change)="filterForm.controls['adimplente'].setValue($event.checked)" checked>
            <span>Adimplente</span>
          </mat-checkbox>
        </div>

        <div class="flex gap-4">
          <button (click)="baixarRelatorioPDF()"
            class="flex items-center justify-center gap-2 px-4 py-2 bg-red-600 text-white font-medium shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 rounded-md">
            <mat-icon class="text-white">file_download</mat-icon>
            <span>Baixar PDF</span>
          </button>

          <button (click)="baixarRelatorioExcel()"
            class="flex items-center justify-center gap-2 px-4 py-2 bg-green-800 text-white font-medium shadow-md hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2 rounded-md">
            <mat-icon class="text-white">file_download</mat-icon>
            <span>Baixar Excel</span>
          </button>

          <button (click)="baixarFichaQualificacao()"
            class="flex items-center justify-center gap-2 px-4 py-2 bg-gray-600 text-white font-medium shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 rounded-md">
            <mat-icon class="text-white">download</mat-icon>
            <span>Baixar Ficha de Qualificação</span>
          </button>
        </div>
      </div>
    </div>

    <div class="p-4">
      <div class="flex justify-between space-x-4">
        <fuse-card class="flex flex-col p-6 bg-green-500 shadow overflow-hidden flex-1">
          <div class="flex items-center space-x-1 pb-4">
            <div class="text-white font-medium text-lg leading-none">Total:</div>
            <div class="text-white font-medium text-3xl leading-none">
              {{ total | number }}
            </div>
          </div>
        </fuse-card>

        <fuse-card class="flex flex-col p-6 bg-green-500 shadow overflow-hidden flex-1">
          <div class="flex items-center space-x-1 pb-4">
            <div class="text-white font-medium text-lg leading-none">Total de Ativos:</div>
            <div class="text-white font-medium text-3xl leading-none">
              {{ totalAtivos | number }}
            </div>
          </div>
        </fuse-card>

        <fuse-card class="flex flex-col p-6 bg-green-500 shadow overflow-hidden flex-1">
          <div class="flex items-center space-x-1 pb-4">
            <div class="text-white font-medium text-lg leading-none">Total de Inativos:</div>
            <div class="text-white font-medium text-3xl leading-none">
              {{ totalInativos | number }}
            </div>
          </div>
        </fuse-card>

        <fuse-card class="flex flex-col p-6 bg-green-500 shadow overflow-hidden flex-1">
          <div class="flex items-center space-x-1 pb-4">
            <div class="text-white font-medium text-lg leading-none">Total de Adimplentes:</div>
            <div class="text-white font-medium text-3xl leading-none">
              {{ totalAdimplente | number }}
            </div>
          </div>
        </fuse-card>

        <fuse-card class="flex flex-col p-6 bg-green-500 shadow overflow-hidden flex-1">
          <div class="flex items-center space-x-1 pb-4">
            <div class="text-white font-medium text-lg leading-none">Total de Inadimplentes:</div>
            <div class="text-white font-medium text-3xl leading-none">
              {{ totalInadimplente | number }}
            </div>
          </div>
        </fuse-card>
      </div>
    </div>

    <mat-card-content style="padding: 0 16px;">
      <table mat-table [dataSource]="paginatedAssociados" class="mat-elevation-z8" style="width: 100%;">

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let associados">{{ associados.pessoa.nome }}</td>
        </ng-container>

        <ng-container matColumnDef="cpf">
          <th mat-header-cell *matHeaderCellDef>CPF</th>
          <td mat-cell *matCellDef="let associados">{{ associados.pessoa.cpf }}</td>
        </ng-container>

        <ng-container matColumnDef="telefone">
          <th mat-header-cell *matHeaderCellDef>Telefone</th>
          <td mat-cell *matCellDef="let associados">{{ associados.pessoa.telefone }}</td>
        </ng-container>

        <ng-container matColumnDef="celular">
          <th mat-header-cell *matHeaderCellDef>Celular</th>
          <td mat-cell *matCellDef="let associados">{{ associados.pessoa.celular }}</td>
        </ng-container>

        <ng-container matColumnDef="vencimento">
          <th mat-header-cell *matHeaderCellDef>Vencimento</th>
          <td mat-cell *matCellDef="let associados">{{ associados.mesVencimentoCobranca ?
            associados.diaVencimentoCobranca.toString().padStart(2, '0') + '/' +
            associados.mesVencimentoCobranca.toString().padStart(2, '0') : '' }}</td>
        </ng-container>

        <ng-container matColumnDef="ativo">
          <th mat-header-cell *matHeaderCellDef>Ativo</th>
          <td mat-cell *matCellDef="let associados">{{ associados.ativo ? 'Ativo' : 'Inativo' }}</td>
        </ng-container>

        <ng-container matColumnDef="adimplente">
          <th mat-header-cell *matHeaderCellDef>Adimplente</th>
          <td mat-cell *matCellDef="let associados">{{ associados.adimplente ? 'Adimplente' : 'Inadimplente' }}</td>
        </ng-container>

        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let associados">
            <button mat-icon-button color="accent" (click)="editAssociados(associados.id!)" matTooltip="Editar">
              <mat-icon>edit</mat-icon>
            </button>


            <button mat-icon-button color="primary" (click)="criarPagamento(associados.id!)"
              matTooltip="Criar pagamento">
              <mat-icon>attach_money</mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="emitirFichaQualificacao(associados)"
              matTooltip="Emitir Ficha de Qualificação">
              <mat-icon>assignment</mat-icon>
            </button>

            <button mat-icon-button color="accent" (click)="openCadastro(associados)" matTooltip="Cadastro">
              <mat-icon>person_add</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="visualizarCarteirinha(associados)" matTooltip="Visualizar Carteirinha">
              <mat-icon>visibility</mat-icon>
            </button>

            <button mat-icon-button color="primary" (click)="iniciarConversa(associados.pessoa)" matTooltip="Iniciar Conversa">
              <mat-icon>message</mat-icon>
            </button>
          </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [length]="filteredAssociados.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons (page)="onPageChange($event)"></mat-paginator>
    </mat-card-content>

  </mat-card>

</div>
