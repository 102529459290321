import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssociadoService } from '../associados.service';
import { SelectSindicatoService } from 'app/shared/components/select-sindicato/select-sindicato.service';
import { toast } from 'app/core/utils/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-criar-pagamento-form',
  templateUrl: './criar-pagamento-form.component.html',
  styleUrls: ['./criar-pagamento-form.component.scss']
})
export class CriarPagamentoFormComponent implements OnInit {
  associadosId?: string;
  errorMessage: string | null = null;
  pagamentoForm: FormGroup;
  pessoaId: number | null = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CriarPagamentoFormComponent>,
    private associadosService: AssociadoService,
    private _snackBar: MatSnackBar,
    private sindicatoService: SelectSindicatoService,
    @Inject(MAT_DIALOG_DATA) public data: { associadosId?: string }
  ) {
    this.pagamentoForm = this.fb.group({
      grupoFiliacao: ['', Validators.required],
      valor: [null, [Validators.required, Validators.min(0)]],
      dataVencimento: [new Date(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.associadosId = this.data.associadosId;

    if (this.associadosId) {
      this.associadosService.getById(this.associadosId).subscribe({
        next: associado => {
          this.pessoaId = associado.pessoa?.id;
        },
        error: err => this.errorMessage = `Erro ao carregar associado: ${err}`
      });
    }

    const sindicatoSelecionado = this.sindicatoService.sindicatoSelecionado;
    if (sindicatoSelecionado) {
      this.pagamentoForm.patchValue({ grupoFiliacao: sindicatoSelecionado.id });
    }
  }

  onSubmit() {
    if (this.pagamentoForm.valid) {
      const pagamentoPayload = {
        grupoFiliacaoId: this.pagamentoForm.get('grupoFiliacao')?.value,
        pessoaId: this.pessoaId,
        valor: this.pagamentoForm.get('valor')?.value,
        criadoEm: new Date(),
        dataVencimento: this.pagamentoForm.get('dataVencimento')?.value,
      };

      this.associadosService.criarPagamento(pagamentoPayload).subscribe({
        next: () => {
          this.dialogRef.close(true);
          toast(
            this._snackBar,
            'Salvo!',
            'O pagamento foi criado com sucesso!.',
            'success',
            true,
            5000
          );
        },
        error: (err) => this.errorMessage = err
      });
    } else {
      this.errorMessage = "Por favor, corrija os erros no formulário.";
    }
  }
}
