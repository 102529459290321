import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarteirinhaService } from './carteirinha.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { Observable } from 'rxjs';
import { Carteirinha } from 'app/model/carteirinha';

@Component({
  selector: 'app-carteirinha',
  templateUrl: './carteirinha.component.html',
  styleUrls: ['./carteirinha.component.scss'],
})
export class CarteirinhaComponent implements OnInit, OnDestroy {
  carteirinha: Carteirinha | null = null;
  error: string | null = null;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private carteirinhaService: CarteirinhaService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadCarteirinha();
  }

  /**
   * Carrega os dados da carteirinha com base no ID do associado fornecido na rota
   */
  loadCarteirinha(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.error = 'ID do associado não fornecido.';
      return;
    }

    this.carteirinhaService
      .consultarCarteirinha(+id)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => this.handleError(error))
      )
      .subscribe((carteirinha) => {
        console.log('Dados da carteirinha:', carteirinha);
        this.carteirinha = carteirinha;
        this.error = !carteirinha ? 'Carteirinha não encontrada.' : null;
        this.cdr.detectChanges();
      });
  }

  /**
   * Centraliza o tratamento de erros
   * @param error Erro recebido
   */
  private handleError(error: any): Observable<null> {
    console.error('Erro ao buscar carteirinha:', error);
    this.error = 'Ocorreu um erro ao carregar os dados da carteirinha.';
    return of(null);
  }

  /**
   * Limpa assinaturas ao destruir o componente
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
