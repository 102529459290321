import { Imagem } from './imagem';
import { Municipio } from './municipio';
import { PlanoContas } from './plano-contas';
import { ContaBancaria } from './conta-bancaria';
import { Campanha } from './campanha';

export class GrupoFiliacao {
  public id?: number;
  public nome?: string;
  public cnpj?: string;
  public endereco?: string;
  public telefone?: string;
  public emailAdm?: string;
  public celular?: string;
  public whatsapp?: boolean;
  public status?: boolean;
  public juntaAdministrativa?: boolean;
  public sindicatoRm?: boolean;
  public dataInicioJuntaAdministrativa?: Date;
  public dataFimJuntaAdministrativa?: Date;
  public dataPublicacaoEdital?: Date;
  public limiteEleicao?: Date;
  public latitude?: number;
  public longitude?: number;
  public dataFundacao?: Date;
  public municipio?: Municipio;
  public logomarca?: Imagem;
  public grupoPai?: GrupoFiliacao;
  public sindicato?: boolean;
  public mensal?: boolean;
  public valor?: number;
  public estatutoAtualizado?: boolean;
  public planoContaPadraoRec?: PlanoContas;
  public contaBancariaPadraoRec?: ContaBancaria & { info: string };
  public campanhaCobranca?: Campanha;

  constructor(grupoFiliacao?: Partial<GrupoFiliacao>) {
    if (grupoFiliacao) {
      Object.assign(this, grupoFiliacao);
    }
  }
}
