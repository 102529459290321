import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Carteirinha } from 'app/model/carteirinha';
import { environment } from 'environments/environment';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarteirinhaService {
  constructor(private http: HttpClient) {}

  consultarCarteirinha(associadoId: number): Observable<Carteirinha> {
    return this.http.get<Carteirinha>(`${environment.api}/carteirinha/${associadoId}`).pipe(
      catchError((error) => {
        throw new Error(error);
      })
    );
  }
}
