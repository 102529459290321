<div class="flex flex-col flex-auto w-full">
  <div class="flex flex-wrap w-full mx-auto p-4">
    <div class="flex items-center justify-between w-full">
      <div>
        <h2 class="text-3xl font-semibold tracking-tight leading-8">Filiados Sem Imovel</h2>
        <div class="font-medium tracking-tight text-secondary">Listagem Detalhada dos Filiados Sem Imovel</div>
      </div>
    </div>

    <div class="w-full mt-6">
      <div class="flex flex-row">
        <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded fuse-mat-dense w-full mb-6 mr-4" [floatLabel]="'always'">
          <mat-icon matPrefix class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [autocomplete]="'off'" [placeholder]="'Buscar'" (keyup)="filtrarFiliadosSemImovel($event.target.value)" />
        </mat-form-field>
        <button mat-flat-button [color]="'primary'" (click)="criarFiliadoSemImovel()">Novo</button>
      </div>
    </div>

    <div class="w-full">
      <table class="w-full" mat-table matSort [dataSource]="filiadosSemImovelDataSource" #filiadosSemImovelTable>
        <!-- Grupo Filiação -->
        <ng-container matColumnDef="grupo_filiacao">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Grupo Filiação</th>
          <td mat-cell *matCellDef="let filiadosSemImovel">
            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              {{ filiadosSemImovel?.grupo.nome ? filiadosSemImovel.grupo.nome : 'Não informado' }}
            </span>
          </td>
        </ng-container>

        <!-- Codigo Faeg Sem Imovel -->
        <ng-container matColumnDef="codigo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Codigo</th>
          <td mat-cell *matCellDef="let filiadosSemImovel">
            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              {{ filiadosSemImovel?.codFaegSemImovel ? filiadosSemImovel.codFaegSemImovel : 'Não informado' }}
            </span>
          </td>
        </ng-container>

        <!-- Nome Pessoa -->
        <ng-container matColumnDef="nome_pessoa">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let filiadosSemImovel">
            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              {{ filiadosSemImovel?.pessoa.nome ? filiadosSemImovel.pessoa.nome : 'Não informado' }}
            </span>
          </td>
        </ng-container>

        <!-- CPF -->>
        <ng-container matColumnDef="cpf_pessoa">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>CPF</th>
          <td mat-cell *matCellDef="let filiadosSemImovel">
            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              {{ filiadosSemImovel?.pessoa.cpf ? filiadosSemImovel.pessoa.cpf : 'Não informado' }}
            </span>
          </td>
        </ng-container>

        <!-- Ações -->
        <ng-container matColumnDef="acoes">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let filiadosSemImovel">
            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              <button mat-icon-button [matTooltip]="'Editar'" (click)="criarFiliadoSemImovel(filiadosSemImovel)">
                <mat-icon [svgIcon]="'edit'"></mat-icon>
              </button>
              <!-- <button mat-icon-button [matTooltip]="'Excluir'" (click)="deletarFiliadosSemImovel(filiadosSemImovel)">
                <mat-icon [svgIcon]="'delete'"></mat-icon>
              </button> -->
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="filiadosSemImovelTableColumns"></tr>
        <tr class="order-row h-16" mat-row *matRowDef="let row; columns: filiadosSemImovelTableColumns"></tr>
      </table>
    </div>
    <div class="p-5 bg-white border border-t-0 border-l-0 border-r-0 border-b-1 w-full"
      *ngIf="filiadosSemImovelDataSource.data.length === 0">
      <label>Nenhum dado encontrado para esse filtro.</label>
    </div>
    <mat-paginator class="w-full" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
      aria-label="Selecione a página"> </mat-paginator>
  </div>
</div>