import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.scss']
})
export class CursoComponent implements OnInit {

  @ViewChild('cursosPaginator', { read: MatSort }) cursosPaginator: MatPaginator;
  @ViewChild('cursosTable', { read: MatSort }) cursosTableTableMatSort: MatSort;
  public cursosDataSource: MatTableDataSource<any> = new MatTableDataSource();
  public cursosTableColumns: string[] = ['descricao'];


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.cursosTableTableMatSort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.cursosPaginator = mp;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _dialogRef: MatDialogRef<CursoComponent>,
  ) { }

  ngAfterViewInit(): void {
    this.cursosDataSource.paginator = this.cursosPaginator;
    this.cursosDataSource.sort = this.cursosTableTableMatSort;
  }

  ngOnInit(): void {
    if (this.data && this.data.cursos) {
      this.cursosDataSource.data = this.data.cursos;
    }
  }

}
