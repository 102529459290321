import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Associado } from 'app/model/associado';
import { AssociadoService } from '../associados.service';
import { MatDialog } from '@angular/material/dialog';
import { AssociadosFormComponent } from '../associados-form/associados-form.component';
import { SelectSindicatoService } from 'app/shared/components/select-sindicato/select-sindicato.service';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { CriarPagamentoFormComponent } from '../criar-pagamento-form/criar-pagamento-form.component';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'jspdf-autotable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IniciarConversaRapidaComponent } from 'app/shared/components/iniciar-conversa-rapida/iniciar-conversa-rapida/iniciar-conversa-rapida.component';
import { Pessoa } from 'app/model/pessoa';

@Component({
  selector: 'app-associados-list',
  templateUrl: './associados-list.component.html',
  styleUrls: ['./associados-list.component.scss'],
})
export class AssociadosListComponent implements OnInit, OnDestroy {
  associados: Associado[] = [];
  filteredAssociados: Associado[] = [];
  paginatedAssociados: Associado[] = [];
  total: number = 0;
  totalAtivos: number = 0;
  totalInativos: number = 0;
  totalAdimplente: number = 0;
  totalInadimplente: number = 0;
  errorMessage: string = '';
  filterForm: FormGroup;
  sindicatoNome: string = '';
  private sindicatoSubscription: Subscription;

  displayedColumns: string[] = ['nome', 'cpf', 'telefone', 'celular', 'vencimento', 'ativo', 'adimplente', 'acoes'];

  pageSize: number = 10;
  pageIndex: number = 0;

  constructor(
    private fb: FormBuilder,
    private associadoService: AssociadoService,
    private _matDialog: MatDialog,
    private _sindicatos: SelectSindicatoService,
    private snackBar: MatSnackBar
  ) {
    this.filterForm = this.fb.group({
      nome: [''],
      ativo: [true],
      adimplente: [true],
    });
  }

  ngOnInit(): void {
    this.sindicatoSubscription = this._sindicatos.sindicatoSelecionado$.subscribe((sindicato) => {
      if (sindicato && sindicato.id) {
        this.sindicatoNome = sindicato.nome;
        this.loadAssociados(sindicato.id);
        this.loadTotals(sindicato.id);
      } else {
        this.resetTotals();
        this.associados = [];
        this.filteredAssociados = [];
        this.paginatedAssociados = [];
      }
    });

    this.filterForm.valueChanges.subscribe(() => this.applyFilter());
  }

  ngOnDestroy(): void {
    this.sindicatoSubscription.unsubscribe();
  }

  loadAssociados(id: string): void {
    this.associadoService.getBySindicato(id).subscribe({
      next: (data) => {
        this.associados = data;
        this.filteredAssociados = data;
        this.applyFilter();
      },
      error: (err) => (this.errorMessage = err),
    });
  }

  loadTotals(sindicatoId: string): void {
    this.associadoService.getTotalAssociadosBySindicato(sindicatoId).subscribe(
      (total) => (this.total = total),
      (error) => console.error('Erro ao carregar total de associados', error)
    );
    this.associadoService.getTotalAtivosBySindicato(sindicatoId).subscribe((totalAtivos) => (this.totalAtivos = totalAtivos));
    this.associadoService.getTotalInativosBySindicato(sindicatoId).subscribe((totalInativos) => (this.totalInativos = totalInativos));
    this.associadoService.getTotalAdimplentesBySindicato(sindicatoId).subscribe((totalAdimplente) => (this.totalAdimplente = totalAdimplente));
    this.associadoService
      .getTotalInadimplentesBySindicato(sindicatoId)
      .subscribe((totalInadimplente) => (this.totalInadimplente = totalInadimplente));
  }

  refreshAssociados(): void {
    const sindicatoSelecionado = this._sindicatos.sindicatoSelecionado;

    if (!sindicatoSelecionado || !sindicatoSelecionado.id) {
      this.snackBar.open('Por favor, selecione um sindicato para atualizar a lista de associados.', 'Fechar', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
      return;
    }

    this.loadAssociados(sindicatoSelecionado.id);
    this.loadTotals(sindicatoSelecionado.id);
    this.pageIndex = 0;
  }

  resetTotals(): void {
    this.total = 0;
    this.totalAtivos = 0;
    this.totalInativos = 0;
    this.totalAdimplente = 0;
    this.totalInadimplente = 0;
  }

  applyFilter(): void {
    const { nome, ativo, adimplente } = this.filterForm.value;

    this.filteredAssociados = this.associados.filter((associado) => {
      const isCpfSearch = /^\d+$/.test(nome);;
      const nomeMatches = !nome || associado.pessoa.nome.toLowerCase().includes(nome.toLowerCase());
      const cpfMatches = !nome || (isCpfSearch && associado.pessoa.cpf.includes(nome));
      const ativoMatches = ativo ? associado.ativo : !associado.ativo;
      const adimplenteMatches = adimplente ? associado.adimplente : !associado.adimplente;

      return (nomeMatches || cpfMatches) && ativoMatches && adimplenteMatches;
    });

    this.updatePaginatedAssociados();
  }

  updatePaginatedAssociados(): void {
    const startIndex = this.pageIndex * this.pageSize;
    this.paginatedAssociados = this.filteredAssociados.slice(startIndex, startIndex + this.pageSize);
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePaginatedAssociados();
  }

  openCadastro(associado: Associado): void {
    const uuid = associado.pessoa.uuid;
    const url = `/crm/pessoas/${uuid}/edit`;
    window.open(url, '_blank');
  }

  baixarRelatorioPDF(): void {
    const sindicatoSelecionado = this._sindicatos.sindicatoSelecionado;

    if (!sindicatoSelecionado || !sindicatoSelecionado.id) {
      this.snackBar.open('Por favor, selecione um sindicato para baixar a lista.', 'Fechar', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
      return;
    }

    const doc = new jsPDF() as any;

    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    doc.setFontSize(10);
    doc.text(`Emitido em: ${dataFormatada}`, doc.internal.pageSize.width - 10, 10, { align: 'right' });

    doc.setFontSize(16);
    doc.text(this.sindicatoNome, doc.internal.pageSize.width / 2, 15, { align: 'center' });

    doc.setFontSize(18);
    doc.text('Relatório de Associados', doc.internal.pageSize.width / 2, 25, { align: 'center' });

    doc.setFontSize(10);
    const headers = ['Nome', 'CPF', 'Telefone', 'Celular', 'Vencimento', 'Ativo', 'Adimplente'];
    const data = this.associados.map(associado => [
      associado.pessoa.nome,
      associado.pessoa.cpf,
      associado.pessoa.telefone,
      associado.pessoa.celular,
      associado.mesVencimentoCobranca ? associado.diaVencimentoCobranca.toString().padStart(2, '0') + '/' + associado.mesVencimentoCobranca.toString().padStart(2, '0') : '',
      associado.ativo ? 'Sim' : 'Não',
      associado.adimplente ? 'Sim' : 'Não',
    ]);

    doc.autoTable({
      head: [headers],
      body: data,
      startY: 35,
      styles: { fontSize: 8 },
    });

    doc.save(`relatorio_associados_${this.sindicatoNome}.pdf`);
  }

  baixarRelatorioExcel(): void {
    const sindicatoSelecionado = this._sindicatos.sindicatoSelecionado;

    if (!sindicatoSelecionado || !sindicatoSelecionado.id) {
      this.snackBar.open('Por favor, selecione um sindicato para baixar a lista.', 'Fechar', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
      return;
    }

    const data = this.associados.map(associado => ({
      Nome: associado.pessoa.nome,
      CPF: associado.pessoa.cpf,
      Telefone: associado.pessoa.telefone,
      Celular: associado.pessoa.celular,
      Vencimento: associado.diaVencimentoCobranca ? associado.diaVencimentoCobranca.toString().padStart(2, '0') + '/' + associado.mesVencimentoCobranca.toString().padStart(2, '0') : ' ',
      Ativo: associado.ativo ? 'Sim' : 'Não',
      Adimplente: associado.adimplente ? 'Sim' : 'Não',
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Associados - ${this.sindicatoNome}`);

    const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelFile]), `relatorio_associados_${this.sindicatoNome}.xlsx`);
  }

  createAssociados(): void {
    const sindicatoSelecionado = this._sindicatos.sindicatoSelecionado;

    if (!sindicatoSelecionado || !sindicatoSelecionado.id) {
      this.snackBar.open('Por favor, selecione um sindicato antes de criar um associado.', 'Fechar', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
      return;
    }

    const grupo = this.associados.length > 0 ? this.associados[0].grupo : null;
    const dialogRef = this._matDialog.open(AssociadosFormComponent, {
      width: '60%',
      height: '90%',
      data: { isEditMode: false, grupo: grupo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && this._sindicatos.sindicatoSelecionado) {
        this.loadAssociados(this._sindicatos.sindicatoSelecionado.id);
        this.loadTotals(this._sindicatos.sindicatoSelecionado.id);
      }
    });
  }

  editAssociados(id: string): void {
    const grupo = this.associados.length > 0 ? this.associados[0].grupo : null;
    const dialogRef = this._matDialog.open(AssociadosFormComponent, {
      width: '60%',
      height: '90%',
      data: { isEditMode: true, associadosId: id, grupo: grupo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && this._sindicatos.sindicatoSelecionado) {
        this.loadAssociados(this._sindicatos.sindicatoSelecionado.id);
        this.loadTotals(this._sindicatos.sindicatoSelecionado.id);
      }
    });
  }

  criarPagamento(id: string): void {
    const dialogRef = this._matDialog.open(CriarPagamentoFormComponent, {
      width: '35%',
      height: '50%',
      data: { associadosId: id },
    });
  }

  baixarFichaQualificacao(): void {
    window.open('/assets/files/ficha_qualificacao.pdf', '_blank');
  }

  emitirFichaQualificacao(associado: Associado): void {
    const doc = new jsPDF();

    doc.setFont('Helvetica');
    doc.setFontSize(12);

    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(`${associado.grupo.nome}`, 105, 20, { align: 'center' });

    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text('F I C H A  D E  Q U A L I F I C A Ç Ã O', 105, 30, { align: 'center' });

    const linhaVazia = '_________________________';
    const getFieldValue = (value: any) => (value ? value : linhaVazia);

    let yPos = 40;

    const formatDate = (date) => {
      if(date != null){
        const d = new Date(date);
        d.setDate(d.getDate() + 1);
        return d.toLocaleDateString('pt-BR');
      }
    };

    const campos = [
      { label: 'Nome completo:', value: associado.pessoa.nome },
      { label: 'Data Nascimento:', value: formatDate(associado.pessoa.dataNascimento) },
      { label: 'Filho de:', value: `${getFieldValue(associado.pessoa.nomePai)} e Sra. ${getFieldValue(associado.pessoa.nomeMae)}` },
      { label: 'Natural de:', value: `${getFieldValue(associado.pessoa.municipioNascimento.nome)} Estado de: ${getFieldValue(associado.pessoa.municipioNascimento.uf)}` },
      { label: 'Estado civil:', value: `${getFieldValue(associado.pessoa.estadoCivil)} Nome/cônjuge: ${getFieldValue(associado.pessoa.nomeConjuge)}` },
      { label: 'Nacionalidade:', value: `${getFieldValue(associado.pessoa.nacionalidade)} Profissão: ${getFieldValue(associado.pessoa.profissao)}` },
      { label: 'Endereço Residencial:', value: `${getFieldValue(associado.pessoa.endereco)} Bairro: ${getFieldValue(associado.pessoa.bairro)}` },
      { label: 'Cidade:', value: `${getFieldValue(associado.pessoa.municipio.nome)} Estado: ${getFieldValue(associado.pessoa.municipio.uf)}` },
      { label: 'Fone Residencial:', value: `${getFieldValue(associado.pessoa.telefone)}` },
      // { label: 'Fone Comercial:', value: associado.telefoneComercial },
      { label: 'Celular:', value: `${getFieldValue(associado.pessoa.celular)} E-mail: ${getFieldValue(associado.pessoa.email)}` },
      {
        label: 'Identidade nº:', value: `${getFieldValue(associado.pessoa.identidade)} Órgão Expedidor: ${getFieldValue(associado.pessoa.orgaoExpedidor)} UF: ${getFieldValue(associado.pessoa.ufOrgaoExpedidor)} `
      },
      { label: 'Data de expedição:', value: `${getFieldValue(formatDate(associado.pessoa.dataExpedicaoIdentidade))} CPF nº: ${getFieldValue(associado.pessoa.cpf)}` },
      { label: 'Data de filiação ao Sindicato:', value: formatDate(associado.dataAssociacao) },
      { label: 'Tempo de exercício na Atividade Rural:', value: `${getFieldValue(associado.tempoExercicioAtividade)} Anos.` },
      { label: 'Outras ocupações ou atividades que exerce atualmente:', value: associado.outrasOcupacoes },
    ];

    campos.forEach((campo) => {
      yPos += 10;
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      doc.text(`${campo.label} ${getFieldValue(campo.value)}`, 10, yPos);
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1)
    const formattedDate = formatDate(currentDate.toISOString());

    yPos += 15;
    doc.text(`${associado.grupo.municipio.nome}, ${formattedDate}.`, 200, yPos, { align: 'right' });

    yPos += 20;
    doc.text('___________________________', doc.internal.pageSize.width / 2, yPos, { align: 'center' });
    doc.text('(Ass. do Candidato)', doc.internal.pageSize.width / 2, yPos + 5, { align: 'center' });

    doc.save(`ficha_qualificacao_${associado.pessoa.nome}.pdf`);
  }
  visualizarCarteirinha(associado: Associado): void {
    // Substitua a URL abaixo pela correta para abrir a visualização da carteirinha
    const url = `/gestao-sindical/associados/carteirinha/${associado.id}`;
    window.open(url, '_blank'); // Abre a carteirinha em uma nova aba
  }

  iniciarConversa(pessoa : Pessoa): void {
    console.log(pessoa);
    const modal = this._matDialog.open(IniciarConversaRapidaComponent, {
      width: '400px',
      autoFocus: true,
      height: '330px',
      data: { pessoa },
    });

    modal.afterClosed().subscribe((novaConversa) => {
      if (novaConversa) {
        //
      }
    });
  }
}
