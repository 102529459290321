import {
  ChangeDetectorRef,
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectSindicatoService } from 'app/shared/components/select-sindicato/select-sindicato.service';
import { AlunosService, Aluno } from '../alunos.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-alunos-list',
  templateUrl: './alunos-list.component.html',
  styleUrls: ['./alunos-list.component.scss'],
})
export class AlunosListComponent implements OnInit, AfterViewInit, OnDestroy {
  alunos: Aluno[] = [];
  searchString: string = '';
  sindicatoId: string | null = null;
  showReminder: boolean = true;
  totalAlunos: number = 0; // Total de alunos
  
  // Colunas da tabela
  alunosTableColumns: string[] = ['nome', 'telefone', 'celular', 'curso'];

  // Datasource da tabela
  alunosDataSource: MatTableDataSource<Aluno> = new MatTableDataSource();

  // ViewChild para paginador e ordenação
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // Unsubscribe
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  filterForm: FormGroup; // Formulário para filtragem

  constructor(
    private _alunosService: AlunosService,
    private _sindicatos: SelectSindicatoService,
    private _changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder // Adicionando FormBuilder
  ) {
    // Inicializar formulário de filtro
    this.filterForm = this.fb.group({
      nome: [''], // Campo de busca pelo nome
    });
  }

  ngOnInit(): void {
    // Configuração do filtro para buscar pelo nome
    this.alunosDataSource.filterPredicate = (data: Aluno, filter: string) => {
      return data.nome.toLowerCase().includes(filter);
    };

    // Monitorar sindicato selecionado
    this._sindicatos.sindicatoSelecionado$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sindicato) => {
        if (sindicato && sindicato.id) {
          this.closeReminder();
          this.carregarAlunos(sindicato.id);
          this.carregarTotalDeAlunos(sindicato.id); // Adicionado
        } else {
          this.alunosDataSource.data = [];
          this.totalAlunos = 0; // Reseta o total de alunos caso o sindicato seja deselecionado
        }
      });

    // Subscrição ao formulário de filtro
    this.filterForm.valueChanges.subscribe(() => this.applyFilter());
  }

  ngAfterViewInit(): void {
    this.alunosDataSource.paginator = this.paginator;
    this.alunosDataSource.sort = this.sort;
  
    // Ordenação inicial (opcional)
    this.sort.active = 'nome'; 
    this.sort.direction = 'asc'; 
  }
  

  ngOnDestroy(): void {
    // Finalizar observáveis
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  carregarAlunos(sindicatoId: string): void {
    this._alunosService.listarAlunosPorSindicato(sindicatoId).subscribe({
      next: (data) => {
        // Atualizar alunos e datasource diretamente
        this.alunos = data;
        this.alunosDataSource.data = data;
        this._changeDetectorRef.detectChanges();
      },
      error: (err) => {
        console.error('Erro ao carregar alunos:', err);
      },
    });
  }

  // Adicionado: método para carregar o total de alunos
  carregarTotalDeAlunos(sindicatoId: string): void {
    this._alunosService.getTotalDeAlunos(sindicatoId).subscribe({
      next: (total) => {
        this.totalAlunos = total;
      },
      error: (err) => {
        console.error('Erro ao carregar o total de alunos:', err);
      },
    });
  }

  closeReminder(): void {
    this.showReminder = false; // Ocultar lembrete ao clicar em "Fechar"
  }

  // Aplicar filtro do formulário
  applyFilter(): void {
    const { nome } = this.filterForm.value;
    this.alunosDataSource.filter = nome.trim().toLowerCase();
  }
}
