<div class="flex flex-col flex-auto w-full">

  <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-6 md:p-8">

    <!-- Title and action buttons -->
    <div class="flex items-center justify-between w-full">
      <div>
        <h2 class="text-3xl font-semibold tracking-tight leading-8">Etiquetas</h2>
      </div>
    </div>

    <div class="w-full">
      <div class="overflow-x-auto w-full">
        <table class="overflow-x-auto w-full" mat-table matSort [dataSource]="cursosDataSource" #cursosTable>

          <!-- Descricao -->
          <ng-container matColumnDef="descricao">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Descrição
            </th>
            <td mat-cell *matCellDef="let objeto">
              <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                {{objeto ? objeto : 'Não informado'}}
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="cursosTableColumns"></tr>
          <tr class="order-row h-16" mat-row *matRowDef="let row; columns: cursosTableColumns;">
          </tr>
        </table>
      </div>
      <div class="p-5 bg-white border border-t-0 border-l-0 border-r-0 border-b-1 w-full"
        *ngIf="cursosDataSource.data.length === 0">
        <label>Nenhum dado encontrado para esse filtro.</label>
      </div>
      <mat-paginator #cursosPaginator class="w-full" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
        aria-label="Selecione a página">
      </mat-paginator>
    </div>
  </div>
</div>
