<div class="flex flex-col flex-auto w-full p-6">
    <div class="flex flex-wrap w-full max-w-screen-xl mx-auto">
        <!-- Title and action buttons -->
        <div class="flex items-center justify-between w-full">
            <div>
                <h2 class="text-3xl font-semibold tracking-tight leading-8">Filiação Sem Imovel</h2>
                <div class="font-medium tracking-tight text-secondary">Informações da Filiação</div>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 items-center gap-4 mt-4">

        <mat-form-field>
            <mat-label>Pessoa</mat-label>
            <input matInput [value]="txtPessoa" [formControl]="controlPessoa" [matAutocomplete]="autoComplete1"
                [required]="true" />
            <mat-autocomplete matNativeControl #autoComplete1="matAutocomplete" name="autoComplete"
                [displayWith]="displayFnPessoa" (optionSelected)="setPessoa($event.option.value)">
                <mat-option *ngFor="let p of pessoas" [value]="p">
                    {{ p.nome }} - {{ p.cpf }}
                </mat-option>
                <mat-option *ngIf="!pessoas.length" disabled>
                    <span>Sem resultados</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field> 

        <mat-form-field>
            <mat-label>Código Faeg</mat-label>
            <input matInput type="number" [(ngModel)]="filiacao.codFaegSemImovel" min="0" [required]="true"/>
        </mat-form-field>
        
        
        <autocomplete-select [title]="'Grupo Filiacao'" [options]="grupoFiliacao" [required]="true"
            [(ngModel)]="filiacao.grupo"> </autocomplete-select>

        <mat-form-field>
            <mat-label>Observação</mat-label>
            <textarea matInput [(ngModel)]="filiacao.observacao" matTextareaAutosize></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Observação Associação</mat-label>
            <textarea matInput [(ngModel)]="filiacao.observacaoAssociacao"></textarea>
        </mat-form-field>

    </div>

    <div class="flex justify-end items-end w-full">
        <mat-checkbox [color]="'primary'" [(ngModel)]="filiacao.situacao">
            <span>Ativo?</span>
        </mat-checkbox>
    </div>

    <div class="grid grid-cols-2 gap-4 w-full mt-4">
        <button mat-flat-button [color]="'warn'" [disabled]="loading" (click)="cancelar()">Cancelar</button>
        <button mat-flat-button [color]="'primary'" [disabled]="loading" (click)="salvar()">
            <span *ngIf="!loading"> Salvar </span>
            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
    </div>
</div>
