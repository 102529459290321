import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutocompleteSelectComponent } from './autocomplete-select.component';
import { SharedModule } from 'app/shared/shared.module';



@NgModule({
  declarations: [
    AutocompleteSelectComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SharedModule
  ],
  exports: [
    AutocompleteSelectComponent
  ]
})
export class AutocompleteSelectModule { }
