import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';

@Component({
  selector: 'app-etiqueta',
  templateUrl: './etiqueta.component.html',
  styleUrls: ['./etiqueta.component.scss']
})
export class EtiquetaComponent implements OnInit {

  @ViewChild('etiquetasPaginator', { read: MatSort }) etiquetasPaginator: MatPaginator;
  @ViewChild('etiquetasTable', { read: MatSort }) etiquetasTableTableMatSort: MatSort;
  public etiquetasDataSource: MatTableDataSource<any> = new MatTableDataSource();
  public etiquetasTableColumns: string[] = ['descricao'];


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.etiquetasTableTableMatSort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.etiquetasPaginator = mp;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _dialogRef: MatDialogRef<EtiquetaComponent>,
  ) { }

  ngAfterViewInit(): void {
    this.etiquetasDataSource.paginator = this.etiquetasPaginator;
    this.etiquetasDataSource.sort = this.etiquetasTableTableMatSort;
  }

  ngOnInit(): void {
    if (this.data && this.data.etiquetas) {
      this.etiquetasDataSource.data = this.data.etiquetas;
    }
  }

}
