import { MatBadgeModule } from '@angular/material/badge';
import { NgxMaskModule } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { GestaoSindicalComponent } from './gestao-sindical.component';
import { CadastrosPessoasComponent } from './cadastros-pessoas/cadastros-pessoas.component';
import { gestaoSindicatoRoutes } from './gestao-sindical.routing';
import { CursoComponent } from './cadastros-pessoas/curso/curso.component';
import { EtiquetaComponent } from './cadastros-pessoas/etiqueta/etiqueta.component';
import { AssociadosModule } from './associados/associados.module';
import { FiliadosSemImovelComponent } from './filiados-sem-imovel/filiados-sem-imovel.component';
import { FiliadosSemImovelModule } from './filiados-sem-imovel/filiados-sem-imovel.module';
import { AlunosModule } from './alunos/alunos.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    GestaoSindicalComponent,
    CadastrosPessoasComponent,
    CursoComponent,
    EtiquetaComponent,
    FiliadosSemImovelComponent,
    
    
  ],
  imports: [
    RouterModule.forChild(gestaoSindicatoRoutes),
    CommonModule,
    MatCardModule,

    AlunosModule,
    MatExpansionModule,
    MatIconModule,
    IonicModule,
    MatAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatBadgeModule,
    AssociadosModule,
    FiliadosSemImovelModule
  ]
})
export class GestaoSindicalModule { }
