import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextMaskModule } from 'angular2-text-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FuseCardModule } from '@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatDialogModule } from '@angular/material/dialog';

import { AsidePanelModule } from 'app/modules/auth/aside-panel/aside-panel.module';
import { SelectSindicatoModule } from './components/select-sindicato/select-sindicato.module';
import { SelectMunicipioComponent } from './components/select-municipio/select-municipio.component';
import { DateComponent } from './components/date/date.component';
import { AutocompleteMunicipioComponent } from './components/autocomplete-municipio/autocomplete-municipio.component';
import { PipeModule } from 'app/pipe/pipe.module';
import { DateMaskDirective } from './directives/date-mask.directive';
import { AutoCompleteDisplayDirective } from './directives/auto-complete-display.directive';

const sharedModules = [
  CommonModule,
  TextMaskModule,
  AsidePanelModule,
  SelectSindicatoModule,
  MatIconModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatButtonModule,
  MatIconModule,
  MatSortModule,
  MatPaginatorModule,
  MatTableModule,
  MatInputModule,
  FormsModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
  FuseCardModule,
  ReactiveFormsModule,
  FuseAlertModule,
  MatCheckboxModule,
  MatTabsModule,
  PipeModule,
  MatRadioModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatChipsModule,
  DragDropModule,
  MatMenuModule,
  // NgxMaskDirective,
  // NgxMaskPipe,
  MatButtonToggleModule,
  NgxCurrencyModule,
  MatDialogModule,
];

@NgModule({
  imports: [...sharedModules, NgxMaskModule.forRoot()],
  exports: [
    ...sharedModules,
    DateComponent,
    NgxMaskModule,
    SelectMunicipioComponent,
    SelectSindicatoModule,
    AutocompleteMunicipioComponent,
    DateMaskDirective,
    AutoCompleteDisplayDirective,
  ],
  declarations: [DateComponent, SelectMunicipioComponent, AutocompleteMunicipioComponent, DateMaskDirective, AutoCompleteDisplayDirective],
})
export class SharedModule {}
