<div class="grid grid-cols-1 gap-8 w-full">
  <!-- Filtro de busca -->
  <div class="flex flex-row p-6">
    <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded fuse-mat-dense w-full mr-4" floatLabel="always">
      <mat-icon matPrefix class="icon-size-5">search</mat-icon>
      <input
        matInput
        [formControl]="filterForm.get('nome')"
        placeholder="Buscar"
        autocomplete="off"
      />
    </mat-form-field>
  </div>

  <!-- Card com o total de alunos -->
  <div class="flex flex-row justify-start px-6">
    <mat-card class="w-1/4 bg-blue-500 text-white p-4">
      <div class="text-lg font-bold">Total de Alunos</div>
      <div class="text-3xl font-bold mt-2">{{ totalAlunos }}</div>
    </mat-card>
  </div>

  <!-- Lembrete -->
  <div *ngIf="showReminder" class="reminder-container">
    <span class="reminder-text">
      Por favor, selecione um sindicato para atualizar a lista de alunos.
    </span>
    <a class="reminder-close" (click)="closeReminder()">Fechar</a>
  </div>

  <!-- Tabela de Alunos -->
  <div class="overflow-x-auto mx-6">
    <table matSort mat-table [dataSource]="alunosDataSource" class="w-full bg-transparent">
      <!-- Coluna Nome -->
      <ng-container matColumnDef="nome">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let aluno">
          <span class="pr-6 capitalize font-medium text-sm text-secondary whitespace-nowrap">
            {{ aluno.nome | uppercase }}
          </span>
        </td>
      </ng-container>

      <!-- Coluna Telefone -->
      <ng-container matColumnDef="telefone">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let aluno">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
            {{ aluno.telefone }}
          </span>
        </td>
      </ng-container>

      <!-- Coluna Celular -->
      <ng-container matColumnDef="celular">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Celular</th>
        <td mat-cell *matCellDef="let aluno">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
            {{ aluno.celular }}
          </span>
        </td>
      </ng-container>

      <!-- Coluna Curso -->
      <ng-container matColumnDef="curso">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Curso</th>
        <td mat-cell *matCellDef="let aluno">
          <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
            {{ aluno.curso }}
          </span>
        </td>
      </ng-container>

      <!-- Header e Rows -->
      <tr mat-header-row *matHeaderRowDef="alunosTableColumns"></tr>
      <tr mat-row class="order-row h-16" *matRowDef="let row; columns: alunosTableColumns"></tr>
    </table>

    <!-- Mensagem caso nenhum dado seja encontrado -->
    <div *ngIf="!alunosDataSource?.data?.length" class="p-5 bg-white border border-t-0 border-l-0 border-r-0 border-b-1 w-full">
      <label>Nenhum dado encontrado.</label>
    </div>

    <!-- Paginação -->
    <mat-paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
      [length]="alunos.length"
      [pageSize]="10"
      aria-label="Selecione a página">
    </mat-paginator>
  </div>
</div>
