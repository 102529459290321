<div class="form-container">
  <mat-card class="mat-elevation-z4">
    <mat-card-header>
      <mat-card-title>
        {{ isEditMode ? (data.grupo?.mensal ? 'Editar Associado - periodicidade mensal' : 'Editar Associado - periodicidade anual') : 'Novo Associado' }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-error *ngIf="errorMessage" class="error-message">{{ errorMessage }}</mat-error>

      <form [formGroup]="associadosForm" (ngSubmit)="onSubmit()">

        <div class="grid grid-cols-2 gap-6 w-full mt-4">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>CPF</mat-label>
            <input type="text" matInput formControlName="cpf" placeholder="Digite o CPF" [readonly]="isEditMode"
              (focusout)="pesquisarPessoaPorCPF()">
            <mat-error *ngIf="associadosForm.get('cpf')?.invalid && associadosForm.get('cpf')?.touched">
              CPF é obrigatório.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Nome</mat-label>
            <input type="text" matInput [value]="pessoa?.nome || ''" readonly>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-3 gap-6 w-full mt-4">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Telefone</mat-label>
            <input matInput formControlName="telefone" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Celular</mat-label>
            <input matInput formControlName="celular" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Dia de Vencimento</mat-label>
            <input type="number" matInput formControlName="diaVencimentoCobranca" placeholder="Dia de Vencimento"
              min="1" max="31" (keypress)="enforceDayRange($event)">
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Mês de Vencimento</mat-label>
            <input type="number" matInput formControlName="mesVencimentoCobranca" placeholder="Mês de Vencimento"
              min="1" max="12" (keypress)="enforceMonthRange($event)">
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Valor de contribuição</mat-label>
            <input matInput formControlName="valorContribuicao" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Data de Associação</mat-label>
            <input matInput formControlName="dataAssociacao" [matDatepicker]="picker" placeholder="Data de Associação">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="associadosForm.get('dataAssociacao')?.invalid && associadosForm.get('dataAssociacao')?.touched">
              Data de associação é obrigatória.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-field" style="grid-column: span 3; width: 100%;">
            <mat-label>Tempo de exercício na Atividade Rural na base territorial do sindicato</mat-label>
            <input type="number" matInput formControlName="tempoExercicioAtividade" placeholder="Anos" min="0">
            <mat-error
              *ngIf="associadosForm.get('tempoExercicioAtividade')?.invalid && associadosForm.get('tempoExercicioAtividade')?.touched">
              Este campo é obrigatório.
            </mat-error>
          </mat-form-field>                

          <mat-form-field appearance="fill" class="form-field" style="grid-column: span 2; width: 100%;">
            <mat-label>Outras Ocupações ou Atividades</mat-label>
            <textarea matInput formControlName="outrasOcupacoes"
              placeholder="Descreva outras ocupações ou atividades"></textarea>
          </mat-form-field>

          <div class="flex items-center space-x-4 mb-4">
            <mat-checkbox formControlName="ativo" color="primary">
              <span>Ativo?</span>
            </mat-checkbox>
            <mat-checkbox formControlName="adimplente" color="primary">
              <span>Adimplente?</span>
            </mat-checkbox>
          </div>

        </div>

        <div class="form-actions">
          <button mat-raised-button color="primary" type="submit" [disabled]="associadosForm.invalid">
            {{ isEditMode ? 'Atualizar' : 'Salvar' }}
          </button>
          <button mat-button type="button" (click)="dialogRef.close()">Cancelar</button>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>