import { Route } from '@angular/router';
import { FiliadosSemImovelComponent } from './filiados-sem-imovel.component';
import { FiliadosSemImovelFormComponent } from './filiados-sem-imovel-form/filiados-sem-imovel-form.component';
import { FiliadosGrupoResolver, FiliadosPessoaResolver, FiliadosSemImovelResolver } from './filiados-sem-imovel.resolvers';



export const filiadosSemImovelRoutes: Route[] = [
  {
    path: '',
    component: FiliadosSemImovelComponent,
    resolve: {
      filiadosSemImovelResolver: FiliadosSemImovelResolver,
      // filiadosPessoaResolver : FiliadosPessoaResolver,
      filiadosGrupoResolver : FiliadosGrupoResolver
    },
    children: [
      {
        path: 'filiados-sem-imovel-form',
        component: FiliadosSemImovelFormComponent,

      },
    ],
  },
];
