import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { toast } from 'app/core/utils/utils';
import { GrupoFiliacao } from './../../../model/grupo-filiacao';
import { EtiquetaComponent } from './etiqueta/etiqueta.component';
import { CursoComponent } from './curso/curso.component';
import { GestaoSindicalService } from '../gestao-sindical.service';
import { CadastrosPessoasDto } from 'app/dto/cadastros-pessoas-dto';

interface Filtro {
  filiados: boolean;
  sindicatoID: number;
  associados: boolean;
  relacionadoMunicipio: boolean;
}

@Component({
  selector: 'app-cadastros-pessoas',
  templateUrl: './cadastros-pessoas.component.html',
  styleUrls: ['./cadastros-pessoas.component.scss'],
})
export class CadastrosPessoasComponent implements OnInit {
  @ViewChild('cadastrosPessoasPaginator', { read: MatPaginator })
  cadastrosPessoasPaginator: MatPaginator;
  @ViewChild('cadastrosPessoasTable', { read: MatSort })
  cadastrosPessoasTableMatSort: MatSort;
  cadastrosPessoasDataSource: MatTableDataSource<any> =
    new MatTableDataSource();
  cadastrosPessoasTableColumns: string[] = [];

  idFiltrado: number;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public txtSindicato: string;

  public columns = [
    'nome',
    'telefone',
    'cpf',
    'endereco',
    'exportar',
    'area',
    'cursos',
    'etiquetas',
  ];
  public sindicatosPorUsuariosFiltrados = [];
  public sindicatosPorUsuarios: [] = [];
  public visible: boolean[];

  public filtro: Filtro = {
    sindicatoID: null,
    filiados: true,
    associados: true,
    relacionadoMunicipio: true,
  };

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _changeDetectorRef: ChangeDetectorRef,
    private _gestaoSindicalService: GestaoSindicalService
  ) {}

  ngOnDestroy(): void {
    this._gestaoSindicalService.limparCadastrosPessoasVinculo();
  }

  ngOnInit(): void {
    this.visible = [true, true, true, true, true, true, true];
    this.cadastrosPessoasTableColumns = this.columns;

    this._gestaoSindicalService.sindicatosPorUsuario$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sindicatosPorUsuario: any) => {
        this.sindicatosPorUsuarios = sindicatosPorUsuario;
      });

    this._gestaoSindicalService.cadastrosPessoasVinculo$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((cadastroPessoas: any) => {
        this.cadastrosPessoasDataSource.data = this.refatorar(cadastroPessoas);

        this.cadastrosPessoasDataSource.paginator =
          this.cadastrosPessoasPaginator;
        this.cadastrosPessoasDataSource.sort =
          this.cadastrosPessoasTableMatSort;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  filtrarSindicatosPorUsuario(desc) {
    if (desc) {
      this.sindicatosPorUsuariosFiltrados = this.sindicatosPorUsuarios?.filter(
        (i: any) =>
          i.nome.toLowerCase().includes(desc.toLowerCase()) &&
          i.nome.toLowerCase() != this.txtSindicato.toLowerCase()
      );
    } else {
      this.sindicatosPorUsuariosFiltrados = this.sindicatosPorUsuarios?.filter(
        (i: any) => i.nome.toLowerCase() != this.txtSindicato.toLowerCase()
      );
      this.txtSindicato = '';
    }
  }

  setSindicato(sindicato: GrupoFiliacao): void {
    this.filtro.sindicatoID = sindicato.id;
  }

  setVisible(): void {
    this.cadastrosPessoasTableColumns = [];
    this.visible.map((e, i) => {
      e ? this.cadastrosPessoasTableColumns.push(this.columns[i]) : null;
    });
  }

  limparCampos(): void {
    this.txtSindicato = null;
    this.filtro = {
      sindicatoID: null,
      filiados: false,
      associados: false,
      relacionadoMunicipio: false,
    };
  }

  filtrar(): void {
    if (!!!this.filtro.sindicatoID) {
      toast(
        this._snackBar,
        'Erro',
        'Selecione um sindicato.',
        'error',
        true,
        5000
      );
      return;
    }

    this._gestaoSindicalService
      .filtrarPessoasVinculo(this.filtro)
      .subscribe({});

    this.idFiltrado = this.filtro.sindicatoID;
  }

  refatorar(cadastroPessoas: []): any {
    const dados = cadastroPessoas.map((objeto: any) => {
      let cadastrosPessoasDto = new CadastrosPessoasDto();
      cadastrosPessoasDto.area = objeto.area;
      cadastrosPessoasDto.pessoa = objeto.pessoa;
      cadastrosPessoasDto.associado = objeto.associado;

      if (objeto.cursos) {
        let cursosStr = objeto.cursos.replace(/[{}]/g, '');
        let cursosArr = cursosStr.split(',');
        cadastrosPessoasDto.cursos = cursosArr;
      }

      if (objeto.etiquetas) {
        let etiqutasStr = objeto.etiquetas.replace(/[{}]/g, '');
        let etiqutasArr = etiqutasStr.split(',');
        cadastrosPessoasDto.etiquetas = etiqutasArr;
      }
      return cadastrosPessoasDto;
    });
    return dados;
  }

  exportarFicha(dados: any) {
    this._gestaoSindicalService
      .exportarFichaAssociacao({
        idSindicato: this.idFiltrado,
        idPessoa: dados?.pessoa?.id,
      })
      .subscribe((r) => {
        var url = window.URL.createObjectURL(new Blob([r.body]));
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = dados?.pessoa?.nome + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }

  listarEtiquetas(etiquetas: []): void {
    const dialogRef = this._dialog.open(EtiquetaComponent, {
      width: '80%',
      height: 'auto',
      data: {
        etiquetas,
      },
    });
    dialogRef.afterClosed().subscribe({});
  }

  listarCursos(cursos: []): void {
    const dialogRef = this._dialog.open(CursoComponent, {
      width: '80%',
      height: 'auto',
      data: {
        cursos,
      },
    });
    dialogRef.afterClosed().subscribe({});
  }

  exportarConsulta(path): void {
    if (!!!this.cadastrosPessoasDataSource.data) {
      toast(
        this._snackBar,
        'Erro',
        'Faz uma consulta para exporta um dado.',
        'error',
        true,
        5000
      );
      return;
    }

    this._gestaoSindicalService
      .exportarCadastroPessoasVinculo(this.filtro)
      .subscribe((r) => {
        var url = window.URL.createObjectURL(new Blob([r.body]));
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `pessoas.${path == 'excel' ? 'xlsx' : 'pdf'}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }
}
