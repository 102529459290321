<div class="form-container">
  <mat-card class="mat-elevation-z4">
      <mat-card-header>
          <mat-card-title>Criar Pagamento</mat-card-title>
      </mat-card-header>

      <mat-card-content>
          <mat-error *ngIf="errorMessage" class="error-message">{{ errorMessage }}</mat-error>

          <form [formGroup]="pagamentoForm" (ngSubmit)="onSubmit()">
              <div class="grid grid-cols-2 gap-6 w-full mt-4">
                  
                  <mat-form-field appearance="fill" class="form-field">
                      <mat-label>Valor</mat-label>
                      <input matInput formControlName="valor" placeholder="Digite o valor" type="number" min="5" step="0.01">
                      <mat-error *ngIf="pagamentoForm.get('valor')?.invalid && pagamentoForm.get('valor')?.touched">
                          Valor é obrigatório e deve ser maior ou igual a 5.
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="form-field">
                      <mat-label>Data de Vencimento</mat-label>
                      <input matInput formControlName="dataVencimento" [matDatepicker]="picker" placeholder="Data de Vencimento">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error *ngIf="pagamentoForm.get('dataVencimento')?.invalid && pagamentoForm.get('dataVencimento')?.touched">
                          Data de vencimento é obrigatória.
                      </mat-error>
                  </mat-form-field>

              </div>

              <div class="form-actions">
                  <button mat-raised-button color="primary" type="submit" [disabled]="pagamentoForm.invalid">Enviar</button>
                  <button mat-button type="button" (click)="dialogRef.close()">Cancelar</button>
              </div>
          </form>
      </mat-card-content>
  </mat-card>
</div>
